import React, {useState, useEffect} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal, useProfile} from "../../context";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

const topics = [
    {
        title: 'Регистрация в акции',
        id: 1
    },
    {
        title: 'Получение призов',
        id: 2
    },
    {
        title: 'Технические вопросы',
        id: 3
    },
    {
        title: 'Вопрос о продукте',
        id: 6
    },
    {
        title: 'Другое',
        id: 4
    }
];

function Feedback({product = false}){
    const {profile} =  useProfile();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [theme, setTheme] = useState('');
    const [themeTitle, setThemeTitle] = useState('');
    const [attachFile, setAttachFile] = useState('');
    const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);

    const { show, hide} = useModal();

    const schema = yup.object({
        name: yup.string().required('Некорректное Имя'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        text: yup.string().min(6, 'Мин. 5 символов').required('Обязательно к заполнению'),
        theme_id: yup.string().required('Выберите тему обращения'),
        agree: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree2: yup.boolean().oneOf([true], 'Необходимо согласие')
    }).required();

    const { register,reset, setValue, clearErrors, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(()=>{
        setValue('agree', true);
        if(product){
            setValue('theme', 6);
        }
    }, []);

    const watchEmail = watch("email");
    const watchName = watch("name");
    const watchMess = watch("text");
    const watchTheme = watch("theme_id");

    useEffect(() => {
        if(!profile.isLoading && Object.keys(profile.data).length > 0){
            setValue('name', profile.data.name);
            setValue('email', profile.data.email);
        }
    },[profile]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        const fileInput = document.querySelector('#file') ;
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("text", data.text);
        formData.append("theme_id", data.theme_id);
        formData.append("agree", 'yes');

        if(fileInput.files[0] !== undefined){
            formData.append('file', fileInput.files[0]);
        }

        try {
            const response = await axiosInstance.post('/feedbacks/api/send', formData);
            if(response.data){
                reset();
                setAttachFile('');
                setThemeTitle('');
                setTheme('');
                show(<Info
                    icon={true}
                    title="спасибо <br/> за обращение"
                    text="Ваш вопрос будет обработан в&nbsp;течение 24&nbsp;часов. <br/>Ответ будет отправлен на&nbsp;указанный электронный адрес"
                    close={hide}/>, "modal--info");

                if(localStorage.getItem('auth_key')){
                    setValue('name', profile.data.name);
                    setValue('email', profile.data.email);
                }
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            обратная связь
                        </div>
                        <div className="modal__subtitle">
                            Мы&nbsp;отправим ответ на&nbsp;указанную электронную почту
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.name ? ' has-error' : '') + ((watchName && watchName != '') ? ' has-value' : '')}>
                                        <label htmlFor="name" className="form__label">
                                            Имя
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            placeholder="Твое имя"
                                            {...register("name")}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p className="error__message">
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            e-mail
                                        </label>
                                        <input
                                            autoComplete="username"
                                            type="text"
                                            id="email"
                                            placeholder="example@email.ru"
                                            {...register("email")}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className="form__label">
                                        Тема обращения
                                    </div>
                                    <div className={'form__select' + (isThemeDropdownOpen ? ' active' : '') + (theme != '' ? ' has-value' : '') + (errors.theme_id ? ' has-error' : '')}>
                                        <div onClick={()=>{
                                            setIsThemeDropdownOpen(!isThemeDropdownOpen);
                                        }} className="form__select-input d-flex align-items-center justify-content-between">
                                            <div className={'form__select-value' + (theme == '' ? ' form__select-value--placeholder' : '')}>
                                                {themeTitle ? themeTitle : 'Выбери тему'}
                                            </div>
                                            <div className="form__select-toggle">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                    <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="form__select-dropdown">
                                            <div className="form__select-dropdown-list">
                                                {topics.map((it, i) => (
                                                    <div onClick={()=>{
                                                        setIsThemeDropdownOpen(false);
                                                        setThemeTitle(it.title);
                                                        setTheme(it.id);
                                                        setValue('theme_id', it.id);
                                                        clearErrors('theme_id');
                                                    }} className={'form__select-dropdown-it' + (it.id == theme ? ' active' : '')}>
                                                        {it.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {errors.theme_id && (
                                        <p className="error__message">
                                            {errors.theme_id?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.text ? ' has-error' : '') + ((watchMess && watchMess != '') ? ' has-value' : '')}>
                                        <label htmlFor="text" className="form__label">
                                            текст сообщения
                                        </label>
                                        <textarea
                                            id="text"
                                            {...register("text")}
                                            placeholder="Введи свое обращение"
                                        />
                                    </div>
                                    {errors.text && (
                                        <p className="error__message">
                                            {errors.text?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it d-flex form__it-justify form__it-center form__bottom">
                                    <label className="d-flex align-items-center form__attach-btn">
                                        <input type="file" id="file" {...register("attachment")} accept="image/png, image/jpeg" onChange={(event)=>{
                                            const fileInput = document.querySelector('#file') ;
                                            const files = event.target.files;
                                            const fileName = files[0].name;
                                            setAttachFile(fileName);
                                        }}/>
                                        <div className={'file__attach-btn-icon d-flex align-items-center justify-content-center' + (attachFile != '' ? ' has-image' : '')}>
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="30" height="30" rx="5.03962" fill="#002169"/>
                                                <path d="M15 7.2832V22.717" stroke="white" stroke-width="2.51981"/>
                                                <path d="M22.7168 15.0001L7.28296 15.0001" stroke="white" stroke-width="2.51981"/>
                                            </svg>
                                        </div>
                                        <span>{attachFile ? attachFile : 'изображение (jpg, png)'}</span>

                                        {attachFile != '' && (
                                            <div onClick={(e)=>{
                                                setValue('attachment', null);
                                                setAttachFile('');
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }} className="file__clear">
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 2.69235L2.69231 2.00005L19.9999 19.3077L19.3076 20L2 2.69235Z" stroke="#CE0E2D" fill="#CE0E2D"></path>
                                                    <path d="M19.3077 2L20 2.69231L2.69237 20L2.00006 19.3076L19.3077 2Z" stroke="#CE0E2D" fill="#CE0E2D"></path>
                                                </svg>
                                            </div>
                                        )}
                                    </label>
                                    <button type="submit" className="btn btn--auto btn--small d-flex">
                                        <span>Отправить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Feedback;
