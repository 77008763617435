import { Link } from 'react-router-dom'
import Info from "./Info";
import {useEffect} from "react";
import axiosInstance from "../../helpers/axios";

function Prize({prize, prize_id, close}){

    useEffect(()=>{
        pushPrize();
    }, []);

    const pushPrize = async () => {
        try {
            const response = await axiosInstance.post('/prizes/api/push/' + prize_id);
            if(response.data){

            }
        } catch (error) {
            if(error.response.data){

            }
        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__title">
                        поздравляем!
                    </div>
                    {prize == 3 && (
                        <div className="modal__subtitle">
                            Ты&nbsp;выиграл гарантированный приз! Промокод будет отправлен на&nbsp;указанный при регистрации e-mail
                        </div>
                    )}
                    {prize == 2 && (
                        <div className="modal__subtitle">
                            Ты&nbsp;выиграл еженедельный приз! Дополнительная информация будет отправлена на&nbsp;указанный при регистрации e-mail
                        </div>
                    )}
                    {prize == 1 && (
                        <div className="modal__subtitle">
                            Ты&nbsp;выиграл ежемесячный приз! Дополнительная информация будет отправлена на&nbsp;указанный при регистрации e-mail
                        </div>
                    )}
                </div>
                <div className="modal__ct-box">
                    <div className="card">
                        {prize == 3 && (
                            <>
                                <div className="card__img">
                                    <img src={require("../../assets/img/c-img-1.jpg")} draggable="false" loading="lazy"/>
                                </div>
                                <div className="card__ct">
                                    <div className="card__subtitle">
                                        гарантированный приз
                                    </div>
                                    <div className="card__title">
                                        промокод <br/>
                                        в онлайн-кинотеатр
                                    </div>
                                </div>
                            </>
                        )}
                        {prize == 2 && (
                            <>
                                <div className="card__img">
                                    <img src={require("../../assets/img/c-img-2.jpg")} draggable="false" loading="lazy"/>
                                </div>
                                <div className="card__ct">
                                    <div className="card__subtitle">
                                        еженедельный приз
                                    </div>
                                    <div className="card__title">
                                        брендированные <br className="mb-only"/>подарки
                                    </div>
                                </div>
                            </>
                        )}
                        {prize == 1 && (
                            <>
                                <div className="card__img">
                                    <img src={require("../../assets/img/c-img-3.jpg")} draggable="false" loading="lazy"/>
                                </div>
                                <div className="card__ct">
                                    <div className="card__subtitle">
                                        ежемесячный приз
                                    </div>
                                    <div className="card__title">
                                        Розыгрыш 15&nbsp;000&nbsp;₽ на&nbsp;события твоего города
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Prize;
