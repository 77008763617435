import {useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useEnd, useModal} from "../../context";
import Reg from "./Reg";
import Pass from "./Pass";
import useEffectOnce from "../../hooks/useEffectOnce";
import axiosInstance from "../../helpers/axios";

function Login(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {end} = useEnd();
    const { show, hide} = useModal();

    const schema = yup.object({
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        password: yup.string().min(6, '6 символов').required('Некорректный пароль'),
        remember: yup.boolean().oneOf([false, true], 'Необходимо согласие'),
    }).required();

    const { register, setValue, watch, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");
    const watchPass = watch("password");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("email", data.email);
        formData.append("password", data.password);

        try {
            const response = await axiosInstance.post('/profiles/api/authorization', formData);
            if(response.data){
                localStorage.setItem('auth_key', response.data);
                window.location.href = process.env.PUBLIC_URL + '/';
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    useEffectOnce(()=>{
        setValue('remember', true);
    });

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            Вход
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            e-mail
                                        </label>
                                        <input
                                            type="text"
                                            id="email"
                                            placeholder="example@email.ru"
                                            autoComplete="nope"
                                            {...register("email")}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.password ? ' has-error' : '') + ((watchPass && watchPass != '') ? ' has-value' : '')}>
                                        <label htmlFor="password" className="form__label">
                                            Пароль
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            placeholder="ВВеди пароль"
                                            autoComplete="current-password"
                                            {...register("password")}
                                        />
                                    </div>
                                    {errors.password && (
                                        <p className="error__message">
                                            {errors.password?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it-justify form__it--action d-flex">
                                    <div className="checkbox__wrap">
                                        <label>
                                            <input
                                                type="checkbox"
                                                {...register("remember")}
                                            />
                                            <div className="checkbox__decor"/>
                                            <div className="checkbox__title">
                                                Запомнить меня
                                            </div>
                                        </label>
                                    </div>
                                    <div className="modal__link modal__link--small">
                                        <a onClick={()=>{
                                            show(<Pass/>, "modal--form modal--pass");
                                        }}>Забыл пароль?</a>
                                    </div>
                                </div>
                                <div className="col-12 form__it form__it-justify form__it-center form__bottom d-flex">
                                    <button type="submit" className="btn btn--small btn--auto d-flex">
                                        <span>Войти</span>
                                    </button>
                                    {!end.end && (
                                        <div className="modal__link">
                                            нет аккаунта? <a onClick={()=>{
                                            show(<Reg/>, "modal--form modal--reg");
                                        }}>зарегистрироваться</a>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Login;
