import React, {useState, useEffect} from "react";
import { Navigate} from 'react-router-dom'
import {useEnd, useModal, useProfile} from "../context";
import axiosInstance from "../helpers/axios";
import useEffectOnce from "../hooks/useEffectOnce";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Info from "../components/modals/Info";
import InputMask from "react-input-mask";
import Login from "../components/modals/Login";
import Header from "../components/Header";
import Footer from "../components/Footer";

const months = [
    {
        title: 'январь',
        id: 1
    },
    {
        title: 'февраль',
        id: 2
    },
    {
        title: 'март',
        id: 3
    },
    {
        title: 'апрель',
        id: 4
    },
    {
        title: 'май',
        id: 5
    },
    {
        title: 'июнь',
        id: 6
    },
    {
        title: 'июль',
        id: 7
    },
    {
        title: 'август',
        id: 8
    },
    {
        title: 'сентябрь',
        id: 9
    },
    {
        title: 'октябрь',
        id: 10
    },
    {
        title: 'ноябрь',
        id: 11
    },
    {
        title: 'декабрь',
        id: 12
    }
];

function Cabinet() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitting2, setIsSubmitting2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updatingProfile, setUpdatingProfile] = useState(false);
    const [dateParts, setDateParts] = useState([0, 0, 0]);

    const [date, setDate] = useState('');
    const [dateTitle, setDateTitle] = useState('');
    const [isDateOpen, setIsDateOpen] = useState(false);

    const [month, setMonth] = useState('');
    const [monthTitle, setMonthTitle] = useState('');
    const [isMonthOpen, setIsMonthOpen] = useState(false);

    const [year, setYear] = useState('');
    const [yearTitle, setYearTitle] = useState('');
    const [isYearOpen, setIsYearOpen] = useState(false);

    const [regSugg, setRegSugg] = useState([]);
    const [ctSugg, setCtSugg] = useState([]);
    const [stSugg, setStSugg] = useState([]);
    const [hsSugg, setHsSugg] = useState([]);

    const [prizes, setPrizes] = useState(null);

    const schemaObj = {
        first_name: yup.string().required('Некорректные ФИО'),
        birthday: yup.string().required('Некорректная дата'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        phone:
            yup.string()
                .test("len", 'Некорректный телефон', (val) => {
                    const val_length_without_dashes = val.replace('+', '').replace(/-|_| /g, "").length;
                    return val_length_without_dashes === 11;
                })
                .required('Некорректный телефон'),
    };

    const schema = yup.object(schemaObj).required();

    const schema2 = yup.object({
        fio: yup.string().required('Некорректный ФИО'),
        city: yup.string().required('Обязательно к заполнению'),
        region: yup.string().required('Обязательно к заполнению'),
        street: yup.string().required('Обязательно к заполнению'),
        postal: yup.string().required('Обязательно к заполнению'),
        house: yup.string().required('Обязательно к заполнению'),
        building: yup.string().nullable(),
        app: yup.string().nullable(),
        phone:
            yup.string()
                .test("len", "Некорректный телефон", (val) => {
                    const val_length_without_dashes = val.replace('+', '').replace(/-|_| /g, "").length;
                    return val_length_without_dashes === 11;
                })
                .required('Некорректный телефон'),
        agree1: yup.boolean().oneOf([true], 'Необходимо согласие'),
    }).required();

    const { register, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const {
        register: register2,
        clearErrors: clearErrors2,
        setValue: setValue2,
        getValues: getValues2,
        watch: watch2,
        resetField,
        setFocus: setFocus2,
        setError: setError2,
        formState: { errors: errors2 }, handleSubmit: handleSubmit2 } = useForm({
        resolver: yupResolver(schema2)
    });

    useEffect(()=>{
        if(dateParts[0] && dateParts[1] && dateParts[2]){
            setValue('birthday', dateParts.reverse().join('-'))
        }else{
            setValue('birthday', '')
        }
    }, [dateParts]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("first_name", data.first_name);
        formData.append("phone", data.phone);
        formData.append("birthday", data.birthday);

        updateProfile(false, formData);
    };

    const onSubmit2 = async (data) => {
        setIsSubmitting2(true);
        const formData = new FormData();
        formData.append("delivery_fullname", data.fio);
        formData.append("delivery_region", data.region);
        formData.append("delivery_city", data.city);
        formData.append("delivery_street", data.street);
        formData.append("delivery_phone", data.phone);
        formData.append("delivery_home", data.house);
        formData.append("delivery_bilding", data.building);
        formData.append("delivery_flat", data.app);
        formData.append("delivery_index", data.postal);
        formData.append("delivery_agree",'yes');

        try {
            const response = await axiosInstance.post('/profiles/api/update-delivery', formData);
            if(response.data){
                setIsSubmitting2(false);
                show(
                    <Info
                        title="Спасибо!"
                        text="Иформация для доставки призов обновлена"
                        icon={true}
                        close={hide}/>,
                    "modal--info"
                );
            }
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError2(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting2(false);
        }
    };

    const updateProfile = async(subscribe = false, formData)=>{
        try {
            const response = await axiosInstance.post('/profiles/api/update-profile', formData);
            if(response.data){
                if(!subscribe){
                    show(
                        <Info
                            title="Спасибо!"
                            text="Данные профиля успешно обновлены."
                            icon={true}
                            close={hide}/>,
                        "modal--info"
                    );
                }else{
                    show(
                        <Info
                            title="Спасибо!"
                            text="Ты&nbsp;успешно подписался на&nbsp;рассылку и&nbsp;участвуешь в&nbsp;розыгрыше ежемесячных призов"
                            icon={true}
                            close={hide}/>,
                        "modal--info"
                    );
                    setProfile({
                        ...profile,
                        data: {
                            ...profile.data,
                            agree_pd: 'yes'
                        }
                    });
                }
            }
            setIsSubmitting(false);
            setUpdatingProfile(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
            setUpdatingProfile(false);
        }
    }

    const {profile, setProfile} =  useProfile();
    const {end} = useEnd();
    const { show, hide} = useModal();

    useEffect(() => {
        if(!profile.isLoading){
            if(profile.data.phone){
                setValue('phone', profile.data.phone)
            }
            if(profile.data.email){
                setValue('email', profile.data.email)
            }
            if(profile.data.first_name){
                setValue('first_name', profile.data.first_name)
            }
            if(profile.data.birthday){
                const dataArr = profile.data.birthday.split('-').reverse();
                setDateParts(dataArr);
                setDateTitle(dataArr[0]);
                setDate(parseInt(dataArr[0]));
                setMonthTitle(months[parseInt(dataArr[1]) - 1].title);
                setMonth(parseInt(dataArr[1]));
                setYearTitle(dataArr[2]);
                setYear(parseInt(dataArr[2]));
            }

            if(profile.data.delivery_city){
                setValue2('city', profile.data.delivery_city);
            }

            if(profile.data.delivery_region){
                setValue2('region', profile.data.delivery_region);
            }

            if(profile.data.delivery_street){
                setValue2('street', profile.data.delivery_street);
            }

            if(profile.data.delivery_phone){
                setValue2('phone', profile.data.delivery_phone);
            }

            if(profile.data.delivery_fullname){
                setValue2('fio', profile.data.delivery_fullname);
            }

            if(profile.data.delivery_home){
                setValue2('house', profile.data.delivery_home);
            }

            if(profile.data.delivery_bilding){
                setValue2('building', profile.data.delivery_bilding);
            }

            if(profile.data.delivery_flat){
                setValue2('app', profile.data.delivery_flat);
            }

            if(profile.data.delivery_index){
                setValue2('postal', profile.data.delivery_index);
            }
        }
    },[profile]);

    useEffectOnce(()=>{
        getPrizes();
    });

    const getRegion = (query)  => {
        let xhttp = new XMLHttpRequest();
        let requestPayload = {
            query: query,
            count: 20,
            from_bound: { value: 'region'},
            to_bound: { value: 'region'}
        };
        xhttp.open("POST", "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5");
        xhttp.setRequestHeader("Accept", "application/json");
        xhttp.setRequestHeader("Authorization", "Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(requestPayload));
        xhttp.onreadystatechange =() => {

            if (!xhttp || xhttp.readyState != 4) {
                return;
            }
            if (xhttp.status == 200) {
                let responseJson = JSON.parse(xhttp.response);
                if (responseJson && responseJson.suggestions) {
                    setRegSugg(responseJson.suggestions);
                }
            }
        };
    }

    const getCity = (query)  => {
        console.log(getValues2(['region']));
        let xhttp = new XMLHttpRequest();
        let requestPayload = {
            query: getValues2(['region'])[0] + ' ' + query,
            count: 20,
            from_bound: { value: 'city'},
            to_bound: { value: 'settlement'},
            restrict_value: true
        };

        xhttp.open("POST", "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5");
        xhttp.setRequestHeader("Accept", "application/json");
        xhttp.setRequestHeader("Authorization", "Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(requestPayload));
        xhttp.onreadystatechange =() => {


            if (!xhttp || xhttp.readyState != 4) {
                return;
            }
            if (xhttp.status == 200) {
                let responseJson = JSON.parse(xhttp.response);
                if (responseJson && responseJson.suggestions) {
                    setCtSugg(responseJson.suggestions);
                }
            }
        };
    }

    const getStreet = (query)  => {
        let xhttp = new XMLHttpRequest();
        let requestPayload = {
            query: getValues2(['region'])[0] + ' ' + getValues2(['city'])[0] + ' ' + query,
            count: 20,
            from_bound: { value: 'street'},
            to_bound: { value: 'street'},
            restrict_value: true
        };
        xhttp.open("POST", "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5");
        xhttp.setRequestHeader("Accept", "application/json");
        xhttp.setRequestHeader("Authorization", "Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(requestPayload));
        xhttp.onreadystatechange =() => {


            if (!xhttp || xhttp.readyState != 4) {
                return;
            }
            if (xhttp.status == 200) {
                let responseJson = JSON.parse(xhttp.response);
                if (responseJson && responseJson.suggestions) {
                    setStSugg(responseJson.suggestions);
                }
            }
        };
    }

    const getHouse = (query)  => {
        let xhttp = new XMLHttpRequest();
        let requestPayload = {
            query: getValues2(['region'])[0] + ' ' + getValues2(['city'])[0] + ' ' + getValues2(['street'])[0] + ' ' + query,
            count: 20,
            from_bound: { value: 'house'},
            to_bound: { value: 'house'},
            restrict_value: true
        };

        xhttp.open("POST", "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address?5");
        xhttp.setRequestHeader("Accept", "application/json");
        xhttp.setRequestHeader("Authorization", "Token d1487020dd0742e0ccdd3ff24fa5d7ccb45ca3bd");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify(requestPayload));
        xhttp.onreadystatechange =() => {


            if (!xhttp || xhttp.readyState != 4) {
                return;
            }
            if (xhttp.status == 200) {
                let responseJson = JSON.parse(xhttp.response);
                if (responseJson && responseJson.suggestions) {
                    setHsSugg(responseJson.suggestions);
                }
            }
        };
    }

    const regionSugg = () => {
        if(regSugg.length > 0) {
            return (
                <div className="sugg__box">
                    <div className="sugg__list">
                        {regSugg.map((sugg, index) => (
                                <div onClick={()=>{
                                    setValue2('region', sugg.value);
                                    setRegSugg([]);
                                    resetField('city');
                                    resetField('street');
                                    resetField('house');
                                    resetField('app');
                                    resetField('postal');
                                }} key={'sugg_' + index.toString()} className="sugg__item">
                                    {sugg.value}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }

    const streetSugg = () => {
        if(stSugg.length > 0) {
            return (
                <div className="sugg__box">
                    <div className="sugg__list">
                        {stSugg.map((sugg, index) => (
                                <div onClick={()=>{
                                    setValue2('street', sugg.data.street_with_type);

                                    setStSugg([]);
                                    resetField('house');
                                    resetField('app');
                                    resetField('postal');

                                }} key={'sugg_' + index.toString()} className="sugg__item">
                                    {sugg.data.street_with_type}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }

    const citySugg = () => {
        if(ctSugg.length > 0) {
            return (
                <div className="sugg__box">
                    <div className="sugg__list">
                        {ctSugg.map((sugg, index) => (
                                <div onClick={()=>{
                                    if(sugg.data.settlement_with_type !== null && sugg.data.settlement_with_type !== undefined) {
                                        setValue2('city', sugg.data.settlement_with_type);
                                        if(sugg.data.area_with_type !== null) {
                                            setValue2('city', sugg.data.settlement_with_type + ', ' + sugg.data.area_with_type);
                                        }
                                    }else{
                                        setValue2('city', sugg.data.city_with_type);
                                    }
                                    if(sugg.data.region_with_type !== null) {
                                        setValue2('region', sugg.data.region_with_type);
                                    }

                                    setCtSugg([]);
                                    resetField('street');
                                    resetField('house');
                                    resetField('app');
                                    resetField('postal');

                                }} key={'sugg_' + index.toString()} className="sugg__item">
                                    {(sugg.data.settlement_with_type !== null && sugg.data.settlement_with_type !== undefined) ? sugg.data.settlement_with_type : sugg.data.city_with_type}
                                    {(sugg.data.area_with_type !== null) ? ', ' + sugg.data.area_with_type : ''}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }

    const houseSugg = () => {
        if(hsSugg.length > 0) {
            return (
                <div className="sugg__box">
                    <div className="sugg__list">
                        {hsSugg.map((sugg, index) => (
                                <div onClick={()=>{
                                    setValue2('house', sugg.data.house + ' ' + ((sugg.data.block !== null) ? ((sugg.data.block_type !== null) ? sugg.data.block_type + ' ' + sugg.data.block : ' ' + sugg.data.block) : ''));
                                    setValue2('postal', sugg.data.postal_code);
                                    setHsSugg([]);
                                    resetField('app');

                                }} key={'sugg_' + index.toString()} className="sugg__item">
                                    {sugg.data.house + ' ' + ((sugg.data.block !== null) ? ((sugg.data.block_type !== null) ? sugg.data.block_type + ' ' + sugg.data.block : ' ' + sugg.data.block) : '')}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }

    const getPrizes = async() => {
        try {
            const response = await axiosInstance.get('/prizes/api/profile-prizes?per-page=100&page=1');
            if(response.data){
                setPrizes(response.data.items.reduce((obj, item) => {
                    obj[item.type] = item
                    return obj
                }, {}));
            }
        } catch (error) {
            if(error.response && error.response.data){

            }
        }
    }

    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/login" replace />)
    }

    return (
        <>
            <Header/>
            <div className="content">
                <div className="sc__lk">
                    <div className="lk-decor-1">
                        <img src={require("../assets/img/lk-decor-1.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="lk-decor-2">
                        <img src={require("../assets/img/lk-decor-2.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="lk-decor-3">
                        <img src={require("../assets/img/lk-decor-3.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                личный кабинет
                            </div>
                        </div>
                        <div className="lk__wrap">
                            <div className="lk__box lk__box--profile">
                                <div className="lk__box-hd">
                                    <div className="lk__box-title">
                                        Данные профиля
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                                    <div className="form__list">
                                        <div className="row">
                                            <div className="col-6 form__it">
                                                <div className={'form__field' + (errors.first_name ? ' has-error' : '')}>
                                                    <label htmlFor="email" className="form__label">
                                                        ФИО (полностью)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="first_name"
                                                        autoComplete="nope"
                                                        placeholder="Введи свое ФИО"
                                                        {...register("first_name")}
                                                    />
                                                </div>
                                                {errors.first_name && (
                                                    <p className="error__message">
                                                        {errors.first_name?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-6 form__it">
                                                <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                                                    <label htmlFor="email" className="form__label">
                                                        e-mail
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="email"
                                                        disabled
                                                        autoComplete="nope"
                                                        placeholder="example@email.ru"
                                                        {...register("email")}
                                                    />
                                                </div>
                                                {errors.email && (
                                                    <p className="error__message">
                                                        {errors.email?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-6 form__it">
                                                <div className="form__label">
                                                    Дата рождения
                                                </div>
                                                <div className="form__select-group">
                                                    <div className={'form__select' + (isDateOpen ? ' active' : '') + (date != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                                        <div onClick={()=>{
                                                            setIsDateOpen(!isDateOpen);
                                                            setIsMonthOpen(false);
                                                            setIsYearOpen(false);
                                                        }} className="form__select-input d-flex">
                                                            <div className={'form__select-value' + (date == '' ? ' form__select-value--placeholder' : '')}>
                                                                {dateTitle ? dateTitle : 'День'}
                                                            </div>
                                                            <div className="form__select-toggle">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                                    <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="form__select-dropdown">
                                                            <div className="form__select-dropdown-list">
                                                                {Array.from({length: 31}).map((it, i) => (
                                                                    <div onClick={()=>{
                                                                        setIsDateOpen(false);
                                                                        setDate((i + 1));
                                                                        setDateTitle(('0' + (i + 1)).slice(-2));
                                                                        setDateParts([('0' + (i + 1)).slice(-2), dateParts[1], dateParts[2]])
                                                                        clearErrors('birthday');
                                                                    }} className={'form__select-dropdown-it' + (it == date ? ' active' : '')}>
                                                                        {('0' + (i + 1)).slice(-2)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'form__select form__select--month' + (isMonthOpen ? ' active' : '') + (month != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                                        <div onClick={()=>{
                                                            setIsMonthOpen(!isMonthOpen);
                                                            setIsDateOpen(false);
                                                            setIsYearOpen(false);
                                                        }} className="form__select-input d-flex">
                                                            <div className={'form__select-value' + (month == '' ? ' form__select-value--placeholder' : '')}>
                                                                {monthTitle ? monthTitle : 'Месяц'}
                                                            </div>
                                                            <div className="form__select-toggle">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                                    <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="form__select-dropdown">
                                                            <div className="form__select-dropdown-list">
                                                                {months.map((it, i) => (
                                                                    <div onClick={()=>{
                                                                        setIsMonthOpen(false);
                                                                        setMonth(it.id);
                                                                        setMonthTitle(it.title);
                                                                        setDateParts([dateParts[0], ('0' + it.id).slice(-2), dateParts[2]])
                                                                        clearErrors('birthday');
                                                                    }} className={'form__select-dropdown-it' + (it.id == month ? ' active' : '')}>
                                                                        {it.title}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'form__select' + (isYearOpen ? ' active' : '') + (year != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                                        <div onClick={()=>{
                                                            setIsYearOpen(!isYearOpen);
                                                            setIsMonthOpen(false);
                                                            setIsDateOpen(false);
                                                        }} className="form__select-input d-flex">
                                                            <div className={'form__select-value' + (year == '' ? ' form__select-value--placeholder' : '')}>
                                                                {yearTitle ? yearTitle : 'Год'}
                                                            </div>
                                                            <div className="form__select-toggle">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                                    <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="form__select-dropdown">
                                                            <div className="form__select-dropdown-list">
                                                                {Array.from({length: 107}).map((it, i) => (
                                                                    <div onClick={()=>{
                                                                        setIsYearOpen(false);
                                                                        setYear((i + 1900));
                                                                        setYearTitle((i + 1900));
                                                                        setDateParts([dateParts[0], dateParts[1], (i + 1900)])
                                                                        clearErrors('birthday');
                                                                    }} className={'form__select-dropdown-it' + (it == year ? ' active' : '')}>
                                                                        {(i + 1900)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {errors.birthday && (
                                                    <p className="error__message">
                                                        {errors.birthday?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-6 form__it">
                                                <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                                                    <label htmlFor="phone" className="form__label">
                                                        Телефон
                                                    </label>
                                                    <InputMask
                                                        autoComplete="nope"
                                                        type="text"
                                                        id="phone"
                                                        mask="+7 999-999-99-99"
                                                        placeholder="+7 999-999-99-99"
                                                        alwaysShowMask={true}
                                                        {...register("phone")}
                                                    />
                                                </div>
                                                {errors.phone && (
                                                    <p className="error__message">
                                                        {errors.phone?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-12 form__it d-flex form__it-justify form__it-center form__bottom">
                                                <button disabled={isSubmitting} type="submit" className="btn btn--auto btn--small d-flex">
                                                    <span>Сохранить изменения</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {!profile.isLoading && Object.keys(profile.data).length > 0 && (
                                <div className="lk__box lk__box--prizes">
                                    <div className="lk__box-hd">
                                        <div className="lk__box-title">
                                            Мои призы
                                        </div>
                                    </div>
                                    <div className="prizes__list">
                                        <div className="prizes__it">
                                            <div className="card">
                                                <div className="card__img">
                                                    <img src={require("../assets/img/c-img-1.jpg")} draggable="false" loading="lazy"/>
                                                </div>
                                                <div className="card__subtitle">
                                                    гарантированный приз
                                                </div>
                                                <div className="card__title">
                                                    промокод в онлайн-<br/>
                                                    кинотеатр
                                                </div>
                                                <div className="prizes__it-status">
                                                    <i className="icon__gift"/>
                                                    <span>отправлен на e-mail</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prizes__it">
                                            <div className="card">
                                                <div className="card__img">
                                                    <img src={require("../assets/img/c-img-2.jpg")} draggable="false" loading="lazy"/>
                                                </div>
                                                <div className="card__subtitle">
                                                    еженедельные призы
                                                </div>
                                                <div className="card__title">
                                                    брендированные подарки
                                                </div>
                                                {(!prizes || !prizes['2']) && (
                                                    <div className="prizes__it-status">
                                                        <i className="icon__approve"/>
                                                        <span>Ты участвуешь в розыгрыше</span>
                                                    </div>
                                                )}

                                                {(prizes && prizes['2']) && (
                                                    <div className="prizes__it-status">
                                                        <i className="icon__gift"/>
                                                        <span>Ты выиграл</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={'prizes__it' + ((!prizes || !prizes[1]) ? ' prizes__it--disabled' : '')}>
                                            <div className="card">
                                                <div className="card__img">
                                                    <img src={require("../assets/img/c-img-3.jpg")} draggable="false" loading="lazy"/>
                                                </div>
                                                <div className="card__subtitle">
                                                    ежемесячный приз
                                                </div>
                                                <div className="card__title card__title--small">
                                                    Розыгрыш 15&nbsp;000&nbsp;₽ на&nbsp;события твоего города*
                                                </div>
                                                {(!prizes || !prizes[1]) && (
                                                    <>
                                                        {profile.data.agree_other === 'yes' && (
                                                            <div className="prizes__it-status">
                                                                <i className="icon__approve"/>
                                                                <span>Ты участвуешь в розыгрыше</span>
                                                            </div>
                                                        )}

                                                        {(!profile.data.agree_other || profile.data.agree_other === 'no') && (
                                                            <div className={'prizes__it-action' + (updatingProfile ? ' form--loading' : '')}>
                                                                <div onClick={()=>{
                                                                    const formData = new FormData();
                                                                    formData.append("agree_other", 'yes');
                                                                    setUpdatingProfile(true);
                                                                    updateProfile(true, formData);
                                                                }} className="btn btn--small d-flex">
                                                                    <div className="btn__ct">
                                                                        <div className="btn__ct-title">
                                                                            Подпишись на рассылку
                                                                        </div>
                                                                        <div className="btn__ct-hint">
                                                                            чтобы участвовать в&nbsp;розыгрыше
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                {(prizes && prizes['1']) && (
                                                    <div className="prizes__it-status">
                                                        <i className="icon__gift"/>
                                                        <span>Ты выиграл</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="lk__box lk__box--delivery">
                                <div className="lk__box-hd">
                                    <div className="lk__box-title">
                                        реквизиты для доставки призов
                                    </div>
                                    <div className="lk__box-subtitle">
                                        Для получения вещественного приза, необходимо указать настоящий адрес для доставки. Получить приз возможно только по&nbsp;настоящему адресу и&nbsp;ФИО получателя. Призовой фонд будет отправлен до&nbsp;15&nbsp;мая 2024 года включительно. Срок хранения посылки 14&nbsp;календарных дней.
                                    </div>
                                </div>
                                <div className="delivery__box">
                                    <form autoComplete="off" onSubmit={handleSubmit2(onSubmit2)} className={'delivery__form' + (isSubmitting2 ? ' form--loading' : '')}>
                                        <div className="form__list">
                                            <div className="row">
                                                <div className="col form__it delivery-col delivery-col--wide">
                                                    <label htmlFor="fio" className="form__label">
                                                        ФИО (полностью) <span>*</span>
                                                    </label>
                                                    <div className={'form__field' + (errors2.fio ? ' has-error' : '')}>
                                                        <input
                                                            type="text"
                                                            id="fio"
                                                            {...register2("fio")}
                                                        />
                                                    </div>
                                                    {errors2.fio && (
                                                        <p className="error__message">
                                                            {errors2.fio?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col form__it delivery-col">
                                                    <label htmlFor="phone" className="form__label">
                                                        Телефон <span>*</span>
                                                    </label>
                                                    <div className={'form__field' + (errors2.phone ? ' has-error' : '')}>
                                                        <InputMask
                                                            autoComplete="nope"
                                                            type="text"
                                                            id="phone"
                                                            mask="+7 999-999-99-99"
                                                            alwaysShowMask={false}
                                                            {...register2("phone")}
                                                        />
                                                    </div>
                                                    {errors2.phone && (
                                                        <p className="error__message">
                                                            {errors2.phone?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col form__it delivery-col delivery-col--wide">
                                                    <label htmlFor="region" className="form__label">
                                                        Область <span>*</span>
                                                    </label>
                                                    <div className={'form__field' + (errors2.region ? ' has-error' : '')}>
                                                        <input
                                                            type="text"
                                                            id="region"
                                                            {...register2("region", {
                                                                onChange: (region) => {
                                                                    getRegion(region.target.value);
                                                                }
                                                            })}
                                                        />
                                                        {regionSugg()}
                                                    </div>
                                                    {errors2.region && (
                                                        <p className="error__message">
                                                            {errors2.region?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col form__it delivery-col">
                                                    <label htmlFor="city" className="form__label">
                                                        Город <span>*</span>
                                                    </label>
                                                    <div className={'form__field' + (errors2.city ? ' has-error' : '')}>
                                                        <input
                                                            type="text"
                                                            id="city"
                                                            {...register2("city", {
                                                                onChange: (city) => {
                                                                    getCity(city.target.value);
                                                                }
                                                            })}
                                                        />
                                                        {citySugg()}
                                                    </div>
                                                    {errors2.city && (
                                                        <p className="error__message">
                                                            {errors2.city?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col form__it delivery-col delivery-col--full">
                                                    <label htmlFor="street" className="form__label">
                                                        Улица <span>*</span>
                                                    </label>
                                                    <div className={'form__field' + (errors2.street ? ' has-error' : '')}>
                                                        <input
                                                            type="text"
                                                            id="street"
                                                            {...register2("street", {
                                                                onChange: (street) => {
                                                                    getStreet(street.target.value);
                                                                }
                                                            })}
                                                        />
                                                        {streetSugg()}
                                                    </div>
                                                    {errors2.street && (
                                                        <p className="error__message">
                                                            {errors2.street?.message}
                                                        </p>
                                                    )}
                                                </div>

                                                <div className="col form__it delivery-col delivery-col--small">
                                                    <label htmlFor="house" className="form__label">
                                                        Номер дома <span>*</span>
                                                    </label>
                                                    <div className={'form__field' + (errors2.house ? ' has-error' : '')}>
                                                        <input
                                                            type="text"
                                                            id="house"
                                                            {...register2("house", {
                                                                onChange: (house) => {
                                                                    getHouse(house.target.value);
                                                                }
                                                            })}
                                                        />
                                                        {houseSugg()}
                                                    </div>
                                                    {errors2.house && (
                                                        <p className="error__message">
                                                            {errors2.house?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col form__it delivery-col delivery-col--small">
                                                    <label htmlFor="building" className="form__label">
                                                        Строение
                                                    </label>
                                                    <div className={'form__field' + (errors2.building ? ' has-error' : '')}>
                                                        <input
                                                            type="text"
                                                            id="building"
                                                            {...register2("building")}
                                                        />
                                                    </div>
                                                    {errors2.building && (
                                                        <p className="error__message">
                                                            {errors2.building?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col form__it delivery-col delivery-col--small">
                                                    <label htmlFor="app" className="form__label">
                                                        Квартира
                                                    </label>
                                                    <div className={'form__field' + (errors2.app ? ' has-error' : '')}>
                                                        <input
                                                            type="text"
                                                            id="app"
                                                            {...register2("app")}
                                                        />
                                                    </div>
                                                    {errors2.app && (
                                                        <p className="error__message">
                                                            {errors2.app?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col form__it delivery-col delivery-col--small">
                                                    <label htmlFor="postal" className="form__label">
                                                        Индекс <span>*</span>
                                                    </label>
                                                    <div className={'form__field form__field--disabled' + (errors2.postal ? ' has-error' : '')}>
                                                        <input
                                                            type="text"
                                                            id="postal"
                                                            {...register2("postal")}
                                                        />
                                                    </div>
                                                    {errors2.postal && (
                                                        <p className="error__message">
                                                            {errors2.postal?.message}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col form__it delivery-col delivery-col--full">
                                                    <div className="checkbox__it">
                                                        <div className={'checkbox__wrap' + (errors2.agree1 ? ' has-error' : '')}>
                                                            <label className="align-items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register2("agree1")}
                                                                />
                                                                <div className="checkbox__decor">
                                                                    <div className="checkbox__decor-bg"/>
                                                                </div>
                                                                <div className="checkbox__title text-left">
                                                                    Подтверждаю корректность внесенной информации для отправки приза
                                                                </div>
                                                            </label>
                                                        </div>
                                                        {errors2.agree1 && (
                                                            <p className="error__message">
                                                                {errors2.agree1?.message}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col form__it form__it delivery-col">
                                                    <button disabled={isSubmitting2} type="submit" className="btn btn--auto btn--small d-flex">
                                                        <span>сохранить</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Cabinet;
