import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'

export const Modal = ({
    isShown,
    modalContent,
    hide,
    className,
  }) => {
    return(
        <Transition appear show={isShown} as={Fragment}>
            <Dialog as="div" className={'modal ' + className} onClose={()=>{hide()}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div id="modal__backdrop" className="modal__backdrop" />
                </Transition.Child>

                <div id="modal__content" className="modal__content">
                    <div className="modal__content-area">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-700"
                            enterFrom="opacity-0 scale-75"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-150"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-75"
                        >
                            <Dialog.Panel className="modal__content-box">
                                <div className="modal__content-box-bl">
                                    <div
                                        id="modal-close"
                                        className="modal__close"
                                        onClick={() => {
                                            hide();
                                        }}
                                    >
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.85452 1.55554L26.8534 23.5544L23.5536 26.8543L1.55469 4.85537L4.85452 1.55554Z" fill="#002169"/>
                                            <path d="M1.55469 23.5544L23.5536 1.55554L26.8534 4.85537L4.85452 26.8543L1.55469 23.5544Z" fill="#002169"/>
                                        </svg>
                                    </div>

                                    {modalContent}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
};
