import React, { useEffect} from "react";
import {useEnd, useModal} from "../context";
import Login from "../components/modals/Login";
import {goToAnchor} from "../helpers/go-to-anchor";
import {findGetParameter} from "../helpers/find-get-param";
import ChangePass from "../components/modals/ChangePass";
import {useNavigate} from "react-router-dom";
import Feedback from "../components/modals/Feedback";

function LoginPage() {
    const { show, hide} = useModal();
    const navigate = useNavigate();

    useEffect(() => {
        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let result = findGetParameter('return') ?? findGetParameter('result');
            let auth_key = findGetParameter('auth_key');
            let recovery = findGetParameter('recovery');

            const url = new URL(window.location.href);
            if(parseInt(result) === 1 && auth_key !== null){
                localStorage.setItem('auth_key', auth_key);
                if(recovery != 1){
                    navigate("/cabinet");
                }else{
                    show(<ChangePass/>, "modal--form modal--pass");
                    navigate("/");
                }

            }
        }

        if(window.location.hash){
            goToAnchor(window.location.hash.substring(1));
            window.history.pushState({param: 'Value'}, '', '/');
        }
    },[]);

    return (
        <div className="content content--start">
            <div className="sc__start sc__login">
                <div className="sc__start-bg-mb mb-only"/>
                <div onClick={()=>{
                    goToAnchor('login');
                }} className="btn btn--small btn--auto btn__login d-flex mb-only">
                    Войти
                </div>
                <div className="container">
                    <div className="start__img">
                        <picture>
                            <source media="(min-width: 1024px)" srcSet={require("../assets/img/login-img.png")}/>
                            <source media="(max-width: 1023px)" srcSet={require("../assets/img/login-img-sm.png")}/>
                            <img width="637"
                                 height="744"
                                 alt=""
                                 draggable="false" loading="lazy"
                                 src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                        </picture>

                        <div className="login__header">
                            <div className="login__title">
                                Выигрывай <br className="mb-only"/>драйвовые призы
                            </div>
                            <div className="login__subtitle">
                                и сертификаты на&nbsp;события твоего города
                            </div>
                        </div>

                        <div className="steps__list desk-only">
                            <div className="steps__it d-flex">
                                <div className="steps__it-numb">
                                    01
                                </div>
                                <div className="steps__it-ct">
                                    <div className="steps__it-title">
                                        Зарегистрируйся
                                    </div>
                                    <div className="steps__it-descr">
                                        на сайте и получи гарантированный <br/>
                                        приз: подписку на онлайн-кинотеатр
                                    </div>
                                </div>
                            </div>
                            <div className="steps__it d-flex">
                                <div className="steps__it-numb">
                                    02
                                </div>
                                <div className="steps__it-ct">
                                    <div className="steps__it-title">
                                        участвуй в розыгрыше
                                    </div>
                                    <div className="steps__it-descr">
                                        брендированных подарков и сертификатов на&nbsp;события
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="steps__list mb-only">
                        <div className="steps__it d-flex">
                            <div className="steps__it-numb">
                                01
                            </div>
                            <div className="steps__it-ct">
                                <div className="steps__it-title">
                                    Зарегистрируйся
                                </div>
                                <div className="steps__it-descr">
                                    на сайте и получи гарантированный <br/>
                                    приз: подписку на онлайн-кинотеатр
                                </div>
                            </div>
                        </div>
                        <div className="steps__it d-flex">
                            <div className="steps__it-numb">
                                02
                            </div>
                            <div className="steps__it-ct">
                                <div className="steps__it-title">
                                    участвуй в розыгрыше
                                </div>
                                <div className="steps__it-descr">
                                    брендированных подарков и сертификатов на&nbsp;события
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login__box modal__content-box">
                        <div id="login" className="anchor"/>
                        <Login/>
                        <div className="login__feedback-box d-flex desk-only">
                            <div className="login__feedback-ct">
                                <div className="login__feedback-title">
                                    Есть вопрос?
                                </div>
                                <div className="login__feedback-descr">
                                    Задай его нам, и&nbsp;мы&nbsp;поможем!
                                </div>
                            </div>
                            <div onClick={()=>{
                                show(<Feedback/>, "modal--form modal--feedback");
                            }} className="btn btn--white d-flex">
                                Задать вопрос
                            </div>
                        </div>
                    </div>
                    <div className="login__feedback-box d-flex mb-only">
                        <div className="login__feedback-ct">
                            <div className="login__feedback-title">
                                Есть вопрос?
                            </div>
                            <div className="login__feedback-descr">
                                Задай его нам, и&nbsp;мы&nbsp;поможем!
                            </div>
                        </div>
                        <div onClick={()=>{
                            show(<Feedback/>, "modal--form modal--feedback");
                        }} className="btn btn--white d-flex">
                            Задать вопрос
                        </div>
                    </div>
                </div>
            </div>
            <div className="start__legal">
                информационная страница промоакции «Лёгкий на подъём»
            </div>
        </div>
    );
}

export default LoginPage;
