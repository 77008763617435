import React, {useEffect, useState} from "react";
import parse from "html-react-parser";
import {useEnd, useModal, useProfile} from "../context";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import {yupResolver} from "@hookform/resolvers/yup";
import Info from "../components/modals/Info";
import {CustomScroll} from 'react-custom-scroll';
import Feedback from "../components/modals/Feedback";
import {goToAnchor} from "../helpers/go-to-anchor";
import {Link, Navigate} from "react-router-dom";
import {pagination} from "../helpers/pagination";
import axiosInstance from "../helpers/axios";
import useEffectOnce from "../hooks/useEffectOnce";
import Reg from "../components/modals/Reg";
import Footer from "../components/Footer";
import Header from "../components/Header";

const faq = [
    {
        title: 'Какие сроки проведения акции?',
        answer: '1. Общий срок проведения Акции, включая срок выдачи призов: с&nbsp;01&nbsp;октября 2024 года по&nbsp;28&nbsp;февраля 2025 года включительно.<br/>' +
            '2. Период регистрации для участия в&nbsp;Акции: с&nbsp;00&nbsp;часов 01&nbsp;минуты по&nbsp;московскому времени 25&nbsp;сентября 2024 года по&nbsp;23&nbsp;часа 59&nbsp;минут по&nbsp;московскому времени 31&nbsp;января 2025&nbsp;года.<br/>' +
            '3. Период определения победителей: с&nbsp;01&nbsp;октября 2024 года по&nbsp;05&nbsp;февраля 2025 года включительно.<br/>' +
            '4. Период выдачи призов: с&nbsp;01&nbsp;октября 2024 года по&nbsp;28&nbsp;февраля 2025 года включительно.'
    },
    {
        title: 'Какая продукция участвует в&nbsp;акции?',
        answer: 'Акция проводится в&nbsp;отношении безалкогольной продукции, выпускаемой под товарным знаком &laquo;Pabst Blue Ribbon&raquo;'
    },
    {
        title: 'Кто может принять участие в&nbsp;акции?',
        answer: 'Участниками Акции могут быть совершеннолетние (достигшие возраста 18&nbsp;лет) дееспособные физические лица, являющиеся гражданами Российской Федерации и&nbsp;постоянно проживающие на&nbsp;территории Российской Федерации. В&nbsp;Акции не&nbsp;допускается участие лиц, не&nbsp;достигших возраста 18&nbsp;лет.'
    },
    {
        title: 'Что необходимо сделать для участия в&nbsp;акции?',
        answer: 'В&nbsp;период с&nbsp;00&nbsp;часов 01&nbsp;минуты по&nbsp;московскому времени 01&nbsp;октября 2024 года по&nbsp;23&nbsp;часа 59&nbsp;минут по&nbsp;московскому времени 31&nbsp;января 2025 года (включительно) отсканировать qr-код, размещенный на&nbsp;упаковке продукции под товарным знаком &laquo;Pabst Blue Ribbon&raquo; или на&nbsp;рекламных материалах в&nbsp;местах продаж продукции под товарным знаком &laquo;Pabst Blue Ribbon&raquo; или в&nbsp;сети Интернет.<br/>' +
            'Пройти регистрацию на&nbsp;сайте <a target="_blank" href="https://promo-pabst0.mosbrew.ru/">https://promo-pabst0.mosbrew.ru/</a>. Для регистрации на&nbsp;сайте <a target="_blank" href="https://promo-pabst0.mosbrew.ru/">https://promo-pabst0.mosbrew.ru/</a> необходимо заполнить специальную форму (Анкету участника).<br/>' +
            'После этого&nbsp;Вы становитесь участником акции и&nbsp;можете претендовать на&nbsp;Гарантированный и&nbsp;Еженедельный приз.'
    },
    {
        title: 'Что необходимо сделать для участия в розыгрыше Ежемесячных призов?',
        answer: 'Для получения возможности выиграть Ежемесячный приз Участник также должен подписаться на&nbsp;e-mail рассылку о&nbsp;событиях в&nbsp;личном кабинете участника.'
    },
    {
        title: 'Какие призы можно выиграть?',
        answer: 'Гарантированный Приз&nbsp;&mdash; промокод на&nbsp;оформление подписки на&nbsp;онлайн-кинотеатр с&nbsp;подпиской на&nbsp;41 (Сорок один) день. <br/>' +
            'Еженедельный Приз&nbsp;&mdash; брендированная сувенирная продукция (одежда и&nbsp;аксессуары) от&nbsp;бренда &laquo;Pabst Blue Ribbon&raquo; (футболка, свитшот, сумка-мешок или рюкзак на&nbsp;усмотрение Организатора) стоимостью до&nbsp;4 000,00 (Четыре тысячи) рублей. Общее количество призов&nbsp;&mdash; 270 штук<br/>' +
            'Ежемесячный Приз&nbsp;&mdash; сертификат на&nbsp;мероприятие &laquo;Яндекс Афиша&raquo; номиналом 15 000,00 (Пятнадцать тысяч) рублей. Общее количество призов&nbsp;&mdash; 8&nbsp;штук.'
    },
    {
        title: 'Какое количество призов можно получить за&nbsp;весь период акции?',
        answer: 'За&nbsp;весь срок проведения Акции один участник может выиграть:<br/>' +
            '&mdash;&nbsp;не&nbsp;более&nbsp;1 (Одного) Гарантированного приза; <br/>' +
            '&mdash;&nbsp;не&nbsp;более&nbsp;1 (Одного) Еженедельного приза;<br/>' +
            '&mdash;&nbsp;не&nbsp;более&nbsp;1 (Одного) Ежемесячного приза.'
    },
    {
        title: 'Как проходит розыгрыш?',
        answer: 'Определение Победителей-обладателей Еженедельных призов проводится Организатором еженедельно в&nbsp;период с&nbsp;01&nbsp;октября 2024 года по&nbsp;31&nbsp;января 2025 года среди зарегистрированных участников.<br/>' +
            'Всего будет проведено&nbsp;18 (Восемнадцать) еженедельных розыгрышей Еженедельных призов, в&nbsp;каждом из&nbsp;которых будет разыграно 15&nbsp;призов в&nbsp;соответствии с&nbsp;графиком из&nbsp;п.&nbsp;9.1.2.1. Правил акции.<br/>' +
            '<br/>' +
            'Определение Победителей-обладателей Ежемесячных призов проводится Организатором ежемесячно в&nbsp;период с&nbsp;01&nbsp;октября 2024 года по&nbsp;31&nbsp;января 2025 года среди участников, которые подписались на&nbsp;email-рассылку событий. Всего будет проведено&nbsp;4 (Четыре) розыгрыша, в&nbsp;каждом из&nbsp;которых будет разыграно&nbsp;2 (Два) Ежемесячных приза. С&nbsp;графиком розыгрышей можно ознакомиться в&nbsp;п.&nbsp;9.1.3.2. Правил акции.'
    },
    {
        title: 'Как можно узнать, что стал победителем?',
        answer: 'Каждому победителю отправляется поздравительное письмо на&nbsp;адрес электронной почты, указанный при регистрации на&nbsp;сайте акции'
    },
    {
        title: 'Как вручаются призы?',
        answer: 'Гарантированные призы вручаются Организатором Победителям в&nbsp;период с&nbsp;01&nbsp;октября 2024&nbsp;г.&nbsp;по&nbsp;28&nbsp;февраля 2025&nbsp;г. (включительно) путем отправки электронного кода на&nbsp;электронный адрес Участника, указанный при регистрации Анкеты на&nbsp;сайте Акции.<br/>' +
            '<br/>' +
            'Еженедельные призы вручаются Организатором Победителям в&nbsp;период с&nbsp;11&nbsp;октября 2024&nbsp;г.<br/>' +
            'по&nbsp;28 февраля 2025&nbsp;г. посредством доставки курьерской службой (доставка осуществляется<br/>' +
            'только на&nbsp;территории Российской Федерации) по&nbsp;адресу, указанному Победителем.<br/>' +
            '<br/>' +
            'Ежемесячные призы вручаются Организатором Победителям в&nbsp;период с&nbsp;11&nbsp;ноября 2024&nbsp;г.&nbsp;по&nbsp;28&nbsp;февраля 2025&nbsp;г. (включительно) путем отправки электронного сертификата на&nbsp;электронный адрес Участника, указанный при регистрации на&nbsp;сайте.'
    },
    {
        title: 'Можно ли получить приз деньгами?',
        answer: 'Денежный эквивалент неденежных призов Организатором не&nbsp;выдается, равно как и&nbsp;замена другими призами не&nbsp;производится.'
    },
    {
        title: 'Как скачать стикерпак для Telegram и&nbsp;Whatsapp?',
        answer: 'Чтобы скачать стикерпак для Telegram, перейдите по&nbsp;ссылке <a href="https://t.me/addstickers/BeerPabst" target="_blank">https://t.me/addstickers/BeerPabst</a> в&nbsp;приложение Telegram и&nbsp;добавьте стикеры к&nbsp;себе. <br/>' +
            'Для добавления стикеров в&nbsp;Whatsapp вам необходимо будет скачать приложение Sticker Maker. Через ссылку <a href="https://stick.rs/pabst2024" target="_blank">https://stick.rs/pabst2024</a> скачайте приложение в&nbsp;AppStore или GooglePlay и&nbsp;добавьте стикеры Pabst в&nbsp;свой WhatsApp. Регистрация в&nbsp;приложении не&nbsp;требуется.'
    },
    {
        title: 'Кто является организатором акции?',
        answer: 'Организатором Акции является юридическое лицо Общество с&nbsp;ограниченной ответственностью &laquo;Инвент&raquo;<br/>' +
            'Сокращенное наименование: ООО &laquo;Инвент&raquo;<br/>' +
            'Место нахождения: Россия, 111033, г. Москва, Таможенный проезд, дом 6&nbsp;стр.3, офис 326<br/>' +
            'Почтовый адрес: Россия, 111033, г. Москва, Таможенный проезд, дом 6&nbsp;стр.3, офис 326'
    },
    {
        title: 'Что делать, если нет ответа на&nbsp;мой вопрос?',
        answer: 'Задайте нам свой вопрос через форму обратной связи на&nbsp;сайте акции. Мы&nbsp;обязательно Вам ответим! Срок предоставления обратной связи может составлять до&nbsp;3х&nbsp;дней.'
    },
];
const periods = [
    {
        title: '28.10 — 03.11',
        start: '2024-10-28',
        end: '2024-11-03'
    },
    {
        title: '21.10 — 27.10',
        start: '2024-10-21',
        end: '2024-10-27'
    },
    {
        title: '14.10 — 20.10',
        start: '2024-10-14',
        end: '2024-10-20'
    },
    {
        title: '07.10 — 13.10',
        start: '2024-10-07',
        end: '2024-10-13'
    },
    {
        title: '01.10 — 06.10',
        start: '2024-10-01',
        end: '2024-10-06'
    }
];
const perPage = 5;
const prizes = {
    1: {
        img: require("../assets/img/c-img-1.jpg")
    },
    2: {
        img: require("../assets/img/c-img-2.jpg")
    },
    3: {
        img: require("../assets/img/c-img-3.jpg")
    },
    4: {
        img: require("../assets/img/c-img-4.jpg")
    },
    5: {
        img: require("../assets/img/c-img-5.jpg")
    },
    6: {
        img: require("../assets/img/c-img-6.jpg")
    },
    7: {
        img: require("../assets/img/c-img-7.jpg")
    },
    8: {
        img: require("../assets/img/c-img-8.jpg")
    }
}
const events = [
    {
        title: 'Рейв «Пиратская станция. Космос»',
        descr: '2 ноября 2024 г., Москва, концертный зал VK Stadium',
        img: require("../assets/img/events/2024-11/01.jpg")
    },
    {
        title: '«Ночь искусств 2024»',
        descr: '3 ноября 2024 г. весь день, Москва',
        img: require("../assets/img/events/2024-11/02.jpg")
    },
    {
        title: '«7 YEARS OF PUNK FICTION»',
        descr: '3-4 ноября, Москва, бар Punk Fiction',
        img: require("../assets/img/events/2024-11/03.jpg")
    },
    {
        title: '«SIGMA Festival — 1 Year»',
        descr: '9 ноября 2024 года, Москва, клуб ARMA',
        img: require("../assets/img/events/2024-11/04.jpg")
    },
    {
        title: '«ТвоРИМ фест» в театре «III РИМ»',
        descr: '15 ноября 2024 г., Москва',
        img: require("../assets/img/events/2024-11/05.jpg")
    },
    {
        title: '«Stand Up по-женски»',
        descr: 'Ноябрь 2024, г. Екатеринбург, м. Геологическая, м. Площадь 1905 года',
        img: require("../assets/img/events/2024-11/06.jpg")
    },
    {
        title: 'Фестиваль «Дягилев. P. S»',
        descr: '6 ноября — 12 декабря, г. Санкт-Петербург, площадь Островского',
        img: require("../assets/img/events/2024-11/07.jpg")
    },
    {
        title: '«Иван Абрамов. Новое и лучшее»',
        descr: '6 ноября 2024 г., Новосибирск, Концертный комплекс им. В. В. Маяковского',
        img: require("../assets/img/events/2024-11/08.jpg")
    }
];

function Home() {
    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/login" replace />)
    }

    const [faqCur, setFaqCur] = useState(0);
    const [faqFull, setFaqFull] = useState(false);
    const [updatingProfile, setUpdatingProfile] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [activePeriod, setActivePeriod] = useState(null);
    const [winnersEmpty, setWinnersEmpty] = useState('Победители не определены');
    const [winners, setWinners] = useState([]);
    const [winPaging, setWinPaging] = useState({
        total: 1,
        current_page: 1
    });
    const {profile, setProfile} =  useProfile();
    const {end} = useEnd();
    const [loading, setLoading] = useState(false);

    const schema = yup.object({
        email: yup.string().email('Некорректный email').required('Некорректный email'),
    }).required();

    const { register, watch, setError, setValue, clearErrors, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");

    useEffectOnce(()=>{
        getWinners(1, false);
    });

    useEffect(()=>{
        if(activePeriod){
            getWinners(1, false);
        }
    }, [activePeriod]);

    const onSearchSubmit = async (data) => {
        setLoading(true);
        setSearchLoading(true);
        setActivePeriod(null);
        getWinners(1, true);
    }

    const { show, hide} = useModal();

    const [curSl1, setCurSl1] = useState(0);
    const [loadedSl1, setLoadedSl1] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        origin: "center",
        loop: false,
        slides: {
            perView: 3,
            spacing: 0,
        },
        breakpoints: {
            '(max-width: 1023px)': {
                slides: {
                    loop: true,
                    perView: 1,
                    spacing: 0,
                },
            },
        },
        slideChanged(slider) {

        },
        created() {
            setLoadedSl1(true)
        },
    });

    const [curSl2, setCurSl2] = useState(0);
    const [loadedSl2, setLoadedSl2] = useState(false);
    const [opacities, setOpacities] = useState([]);
    const [sliderRef2, instanceRef2] = useKeenSlider({
        loop: true,
        slides: {
            perView: 6,
            spacing: 0,
        },
        defaultAnimation: {
            duration: 1200
        },
        breakpoints: {
            '(max-width: 1023px)': {
                slides: {
                    perView: 1,
                    spacing: 0,
                },
            },
        },
        slideChanged: (s) => {
            //this.current = s.track.details.rel
        },
        created(s) {
            setLoadedSl2(true);
        },
    }, [
        (slider) => {
            let timeout
            let mouseOver = false
            function clearNextTimeout() {
                clearTimeout(timeout)
            }
            function nextTimeout() {
                clearTimeout(timeout)
                if (mouseOver) return
                timeout = setTimeout(() => {
                    slider.next()
                }, 3000)
            }
            slider.on("created", () => {
                nextTimeout()
            })
            slider.on("dragStarted", clearNextTimeout)
            slider.on("animationEnded", nextTimeout)
            slider.on("updated", nextTimeout)
        },
    ]);

    const [curSl3, setCurSl3] = useState(0);
    const [loadedSl3, setLoadedSl3] = useState(false);
    const [sliderRef3, instanceRef3] = useKeenSlider({
        loop: true,
        slides: {
            perView: 4,
            spacing: 0,
        },
        defaultAnimation: {
            duration: 1200
        },
        breakpoints: {
            '(max-width: 1023px)': {
                slides: {
                    perView: 1,
                    spacing: 0,
                },
            },
        },
        slideChanged(slider) {

        },
        created() {
            setLoadedSl3(true)
        },
    });

    const getWinners = async(page = 1, search = null) => {
        setLoading(true);

        try {
            const response = await axiosInstance.get('/prizes/api/winners?per-page=' + perPage + '&page=' + page + (search ? '&filter[and][1][user_email][like]=' + watchEmail : '') + ((activePeriod && !search) ? '&filter[and][1][use_at][gte]=' + activePeriod.start + '+00:00:00&filter[and][1][use_at][lte]=' + activePeriod.end + '+23:59:59' : ''));
            if(response.data){
                setWinners(response.data.items);
                setWinPaging({
                    total: response.data._meta.pageCount,
                    current_page: response.data._meta.currentPage
                });
                setLoading(false);
                setSearchLoading(false);

                if(search){
                    setWinnersEmpty('Среди победителей данный аккаунт не найден');
                }else{
                    setWinnersEmpty('Победители не определены');
                }
            }
        } catch (error) {
            if(error.response && error.response.data){

            }

            setLoading(false);
            setSearchLoading(false);
        }
    }

    const renderPager = () => {
        let paging_arr = pagination(parseInt(winPaging.current_page), winPaging.total);
        if(winPaging.total > 1){
            return(
                <div className="pager d-flex">
                    <div onClick={()=>{
                        if(winPaging.current_page !== 1){
                            getWinners(parseInt(winPaging.current_page) - 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--prev d-flex align-items-center justify-content-center ' + ((winPaging.current_page === 1) ? 'disabled' : '')}>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.66683 21.9998C3.66683 32.1251 11.8749 40.3332 22.0002 40.3332C32.1254 40.3332 40.3335 32.1251 40.3335 21.9998C40.3335 11.8746 32.1254 3.6665 22.0002 3.6665C11.8749 3.6665 3.66683 11.8746 3.66683 21.9998Z" fill="#002169"/>
                            <path d="M22 14.6665L14.6667 21.9998L22 29.3332" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M29.3335 22L14.6668 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className="pager__list d-flex">
                        {
                            paging_arr.map((page, index) => (
                                <div onClick={()=>{
                                    if(winPaging.current_page !== parseInt(page)){
                                        getWinners(parseInt(page))
                                    }
                                }} className={'pager__item ' + ((page == winPaging.current_page) ? 'active' : '') + ((page === '...') ? 'pager__item-dots' : '')}>
                                    <span>{page}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div onClick={()=>{
                        if(winPaging.total !== winPaging.current_page){
                            getWinners(parseInt(winPaging.current_page) + 1);
                        }
                    }} className={'box__sl-nav box__sl-nav--next d-flex align-items-center justify-content-center ' + ((winPaging.total === winPaging.current_page) ? 'disabled' : '')}>
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.66683 21.9998C3.66683 32.1251 11.8749 40.3332 22.0002 40.3332C32.1254 40.3332 40.3335 32.1251 40.3335 21.9998C40.3335 11.8746 32.1254 3.6665 22.0002 3.6665C11.8749 3.6665 3.66683 11.8746 3.66683 21.9998Z" fill="#002169"/>
                            <path d="M22.0002 29.3332L29.3335 21.9998L22.0002 14.6665" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M14.6668 22L29.3335 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
            )
        }
    }

    const updateAgree = async() => {
        setUpdatingProfile(true);
        const formData = new FormData();
        formData.append("agree_pd", 'yes');

        try {
            const response = await axiosInstance.post('/profiles/api/update-profile', formData);
            if(response.data){
                show(
                    <Info
                        title="Спасибо!"
                        text="Ты&nbsp;успешно подписался на&nbsp;рассылку и&nbsp;участвуешь в&nbsp;розыгрыше ежемесячных призов"
                        icon={true}
                        close={hide}/>,
                    "modal--info"
                );
                setProfile({
                    ...profile,
                    data: {
                        ...profile.data,
                        agree_pd: 'yes'
                    }
                });
            }
            setUpdatingProfile(false);
        } catch (error) {
            setUpdatingProfile(false);
        }
    }

    return (
        <>
            <Header/>
            <div className="content">
                <div className={'sc__promo' + (profile.loggedIn ? ' sc__promo--loggedin' : '')}>
                    <div id="promo" className="anchor"/>
                    <div className="sc__promo-bg">
                        <picture>
                            <source media="(min-width: 1024px)" srcSet={require("../assets/img/promo-desk.jpg")}/>
                            <source media="(max-width: 1023px)" srcSet={require("../assets/img/promo-mb.jpg")}/>
                            <img width="1920"
                                 height="750"
                                 alt=""
                                 draggable="false" loading="lazy"
                                 src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                        </picture>
                    </div>
                    <div className="promo__product">
                        <div className="promo__product-logo">
                            <img src={require("../assets/img/promo-logo.svg").default} draggable="false" loading="lazy"/>
                        </div>
                        <div className="promo__decor-1">
                            <img src={require("../assets/img/promo-decor-1.svg").default} draggable="false" loading="lazy"/>
                        </div>
                        <div className="promo__decor-2">
                            <img src={require("../assets/img/promo-decor-2.svg").default} draggable="false" loading="lazy"/>
                        </div>
                        <div className="promo__decor-3">
                            <img src={require("../assets/img/promo-decor-3.svg").default} draggable="false" loading="lazy"/>
                        </div>
                        <div className="promo__product-img">
                            <img src={require("../assets/img/promo-product.png")} draggable="false" loading="lazy"/>
                        </div>
                    </div>
                    <div className="container">
                        <div className="sc__promo-ct">
                            <div className="sc__promo-title">
                                Выигрывай <br/>
                                драйвовые <br className="desk-only"/>
                                призы
                            </div>
                            <div onClick={()=>{
                                goToAnchor('steps');
                            }} className="promo__scroll mb-only">
                                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L7 7L13 1" stroke="white" strokeWidth="2"/>
                                </svg>
                            </div>
                            {!profile.loggedIn && (
                                <div className="d-flex sc__promo-action">
                                    <div onClick={()=>{
                                        show(<Reg/>, "modal--form modal--reg");
                                    }} className="btn btn--shadow d-flex">
                                        участвовать
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="zero__decor">
                        <img draggable="false" loading="lazy" src={require("../assets/img/zero-decor.svg").default}/>
                    </div>
                </div>
                <div className="sc__steps">
                    <div id="steps" className="anchor"/>
                    <div className="steps__decor-1 desk-only">
                        <img src={require("../assets/img/steps-decor-1.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="steps__decor-2 desk-only">
                        <img src={require("../assets/img/steps-decor-2.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="steps__decor-3 desk-only">
                        <img src={require("../assets/img/steps-decor-3.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Как участвовать?
                            </div>
                        </div>
                        <div className="steps__wrap d-flex">
                            <div className="steps__left">
                                <div className="steps__list">
                                    <div className="steps__it d-flex">
                                        <div className="steps__it-numb">
                                            01
                                        </div>
                                        <div className="steps__it-ct">
                                            <div className="steps__it-title">
                                                Зарегистрируйся
                                            </div>
                                            <div className="steps__it-descr">
                                                и получи гарантированный приз
                                            </div>
                                        </div>
                                    </div>
                                    <div className="steps__it d-flex">
                                        <div className="steps__it-numb">
                                            02
                                        </div>
                                        <div className="steps__it-ct">
                                            <div className="steps__it-title">
                                                участвуй в розыгрыше
                                            </div>
                                            <div className="steps__it-descr">
                                                еженедельных брендированных подарков
                                            </div>
                                        </div>
                                    </div>
                                    <div className="steps__it d-flex">
                                        <div className="steps__it-numb">
                                            03
                                        </div>
                                        <div className="steps__it-ct">
                                            <div className="steps__it-title">
                                                подпишись на рассылку
                                            </div>
                                            <div className="steps__it-descr">
                                                и участвуй в
                                                розыгрыше ежемесячного приза
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="steps__right">
                                <div className="steps__sl-box">
                                    <div onClick={()=>{
                                        instanceRef.current?.prev();
                                    }} className="sl__nav sl__nav--prev mb-only">
                                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.66683 22.0001C3.66683 32.1253 11.8749 40.3334 22.0002 40.3334C32.1254 40.3334 40.3335 32.1253 40.3335 22.0001C40.3335 11.8749 32.1254 3.66675 22.0002 3.66675C11.8749 3.66675 3.66683 11.8749 3.66683 22.0001Z" fill="#002169"/>
                                            <path d="M22 14.6667L14.6667 22.0001L22 29.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M29.3335 22L14.6668 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div onClick={()=>{
                                        instanceRef.current?.next();
                                    }} className="sl__nav sl__nav--next mb-only">
                                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.66683 22.0001C3.66683 32.1253 11.8749 40.3334 22.0002 40.3334C32.1254 40.3334 40.3335 32.1253 40.3335 22.0001C40.3335 11.8749 32.1254 3.66675 22.0002 3.66675C11.8749 3.66675 3.66683 11.8749 3.66683 22.0001Z" fill="#002169"/>
                                            <path d="M22.0002 29.3334L29.3335 22.0001L22.0002 14.6667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.6668 22L29.3335 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="steps__sl-row">
                                        <div className="steps__sl">
                                            <div ref={sliderRef} className="keen-slider">
                                                <div className="keen-slider__slide">
                                                    <div className="steps__slide">
                                                        <div className="card">
                                                            <div className="card__img">
                                                                <img src={require("../assets/img/c-img-1.jpg")} draggable="false" loading="lazy"/>
                                                            </div>
                                                            <div className="card__subtitle">
                                                                гарантированный приз
                                                            </div>
                                                            <div className="card__title">
                                                                промокод в онлайн-<br/>
                                                                кинотеатр
                                                            </div>
                                                            <div className="card__hint">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="keen-slider__slide">
                                                    <div className="steps__slide">
                                                        <div className="card">
                                                            <div className="card__img">
                                                                <img src={require("../assets/img/c-img-2.jpg")} draggable="false" loading="lazy"/>
                                                            </div>
                                                            <div className="card__subtitle">
                                                                еженедельные призы
                                                            </div>
                                                            <div className="card__title">
                                                                брендированные подарки *
                                                            </div>
                                                            <div className="card__hint">
                                                                * среди тех, кто прошел регистрацию
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="keen-slider__slide">
                                                    <div className="steps__slide">
                                                        <div className="card">
                                                            <div className="card__img">
                                                                <img src={require("../assets/img/c-img-3.jpg")} draggable="false" loading="lazy"/>
                                                            </div>
                                                            <div className="card__subtitle">
                                                                ежемесячный приз
                                                            </div>
                                                            <div className="card__title card__title--small">
                                                                розыгрыш 15&nbsp;000&nbsp;₽ на&nbsp;события твоего города*
                                                            </div>
                                                            <div className="card__hint">
                                                                * среди тех, кто подписался на афишу
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__stickers">
                    <div className="container">
                        <div className="stickers__ct">
                            <div className="stickers__img">
                                <img src={require("../assets/img/sticker.png")} draggable="false" loading="lazy"/>
                            </div>
                            <div className="stickers__title">
                                нажми на кнопку <br/>
                                и получи стикерпак
                            </div>
                        </div>
                        <div className="stickers__sep mb-only"/>
                        <a href="https://t.me/addstickers/BeerPabst" target="_blank" className="btn btn--white btn--sticker d-flex">
                            <div className="btn__title">
                                стикерпак <br/>
                                для Telegram
                            </div>
                            <div className="btn__icon">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="60" height="60" fill="#002169"/>
                                    <path d="M40.2555 19.0174C39.8986 18.9713 39.5352 19.0172 39.2033 19.1504L16.2317 28.3661C14.6304 29.0086 14.5485 31.2153 16.2331 31.8809C17.4797 32.3737 19.0071 32.7781 20.6541 33.0393C21.5568 33.1826 22.3118 33.0393 23.7599 32.1996L34.1307 26.2798C31.444 28.7591 26.7422 32.9292 26.4467 34.0389C26.1028 35.3368 28.901 37.1607 29.8145 37.7303C31.6415 38.8682 33.6726 39.9587 35.0402 40.6666C36.675 41.5126 38.631 40.5386 38.9265 38.8401L41.9733 21.2316C42.0317 20.8929 41.9939 20.5456 41.8636 20.2256C41.7334 19.9057 41.5155 19.6248 41.2327 19.4123C40.9498 19.1998 40.6124 19.0635 40.2555 19.0174Z" fill="white"/>
                                </svg>
                            </div>
                        </a>
                        <a href="https://stick.rs/pabst2024" target="_blank" className="btn btn--white btn--sticker d-flex">
                            <div className="btn__title">
                                стикерпак* <br/>
                                для whatsapp
                            </div>
                            <div className="btn__icon">
                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="60" height="60" fill="#002169"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M39.2118 20.7784C36.7677 18.3432 33.5172 17.0014 30.0544 17C22.9188 17 17.1115 22.7794 17.1086 29.8827C17.1076 32.1534 17.7037 34.37 18.8366 36.3239L17 43L23.8628 41.2084C25.7537 42.2349 27.8826 42.7759 30.0491 42.7766H30.0545C37.1893 42.7766 42.9973 36.9967 43 29.893C43.0014 26.4504 41.6561 23.2136 39.2118 20.7784ZM35.9564 32.5806C35.633 32.4194 34.0426 31.6409 33.7461 31.5333C33.4498 31.4258 33.2339 31.3724 33.0185 31.6945C32.8027 32.0167 32.1829 32.7419 31.9941 32.9566C31.8053 33.1714 31.6169 33.1984 31.2933 33.0372C30.9698 32.8761 29.9276 32.536 28.692 31.4393C27.7305 30.5857 27.0813 29.5315 26.8926 29.2094C26.7041 28.8869 26.891 28.7295 27.0345 28.5524C27.3846 28.1197 27.7353 27.666 27.8431 27.4513C27.951 27.2364 27.897 27.0483 27.816 26.8873C27.7353 26.7262 27.0884 25.1417 26.8189 24.4969C26.5561 23.8694 26.2897 23.9542 26.091 23.9443C25.9025 23.935 25.6868 23.9331 25.471 23.9331C25.2554 23.9331 24.905 24.0135 24.6084 24.336C24.3119 24.6583 23.4764 25.437 23.4764 27.0215C23.4764 28.6061 24.6355 30.1368 24.7972 30.3517C24.9588 30.5665 27.0782 33.8181 30.3229 35.2124C31.0947 35.5444 31.6971 35.7422 32.167 35.8906C32.9419 36.1356 33.6469 36.101 34.2043 36.0182C34.8259 35.9257 36.1179 35.2394 36.3877 34.4875C36.6572 33.7354 36.6572 33.091 36.5762 32.9566C36.4955 32.8223 36.2798 32.7419 35.9564 32.5806Z" fill="white"/>
                                </svg>
                            </div>
                        </a>

                        <div className="stickers__hint">
                            * Получи стикерпак для WhatsApp через приложение Sticker Maker. Через ссылку скачай приложение в&nbsp;AppStore или GooglePlay и&nbsp;добавь стикеры Pabst в&nbsp;свой WhatsApp . Регистрация в&nbsp;приложении не&nbsp;требуется.
                        </div>
                    </div>
                </div>
                <div className="sc__prizes">
                    <div id="prizes" className="anchor"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                призы
                            </div>
                        </div>
                        <div className="prizes__sl-box">
                            <div onClick={()=>{
                                instanceRef2.current?.prev();
                            }} className="sl__nav sl__nav--prev mb-only">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.66683 22.0001C3.66683 32.1253 11.8749 40.3334 22.0002 40.3334C32.1254 40.3334 40.3335 32.1253 40.3335 22.0001C40.3335 11.8749 32.1254 3.66675 22.0002 3.66675C11.8749 3.66675 3.66683 11.8749 3.66683 22.0001Z" fill="#002169"/>
                                    <path d="M22 14.6667L14.6667 22.0001L22 29.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M29.3335 22L14.6668 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div onClick={()=>{
                                instanceRef2.current?.next();
                            }} className="sl__nav sl__nav--next mb-only">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.66683 22.0001C3.66683 32.1253 11.8749 40.3334 22.0002 40.3334C32.1254 40.3334 40.3335 32.1253 40.3335 22.0001C40.3335 11.8749 32.1254 3.66675 22.0002 3.66675C11.8749 3.66675 3.66683 11.8749 3.66683 22.0001Z" fill="#002169"/>
                                    <path d="M22.0002 29.3334L29.3335 22.0001L22.0002 14.6667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.6668 22L29.3335 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="prizes__sl-row">
                                <div className="prizes__sl">
                                    <div ref={sliderRef2} className="keen-slider">
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-1.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        гарантированный приз
                                                    </div>
                                                    <div className="card__title">
                                                        промокод в онлайн-<br/>
                                                        кинотеатр
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-4.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        брендированные подарки
                                                    </div>
                                                    <div className="card__title">
                                                        Рюкзак
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-5.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        брендированные подарки
                                                    </div>
                                                    <div className="card__title">
                                                        свитшот
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-6.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        брендированные подарки
                                                    </div>
                                                    <div className="card__title">
                                                        футболка
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-7.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        брендированные подарки
                                                    </div>
                                                    <div className="card__title">
                                                        сумка-мешок
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-3.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        ежемесячный приз
                                                    </div>
                                                    <div className="card__title card__title--small">
                                                        Сертификат 15&nbsp;000 рублей на&nbsp;события твоего города*
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-1.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        гарантированный приз
                                                    </div>
                                                    <div className="card__title">
                                                        промокод в онлайн-<br/>
                                                        кинотеатр
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-4.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        брендированные подарки
                                                    </div>
                                                    <div className="card__title">
                                                        Рюкзак
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-5.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        брендированные подарки
                                                    </div>
                                                    <div className="card__title">
                                                        свитшот
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-6.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        брендированные подарки
                                                    </div>
                                                    <div className="card__title">
                                                        футболка
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-7.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        брендированные подарки
                                                    </div>
                                                    <div className="card__title">
                                                        сумка-мешок
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="steps__slide">
                                                <div className="card">
                                                    <div className="card__img">
                                                        <img src={require("../assets/img/c-img-3.jpg")} draggable="false" loading="lazy"/>
                                                    </div>
                                                    <div className="card__subtitle">
                                                        ежемесячный приз
                                                    </div>
                                                    <div className="card__title card__title--small">
                                                        Сертификат 15&nbsp;000&nbsp;₽ на&nbsp;события твоего города*
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!profile.loggedIn && (
                            <div className="prizes__sl-action d-flex">
                                <div onClick={()=>{
                                    show(<Reg/>, "modal--form modal--reg");
                                }} className="btn d-flex">
                                    участвовать
                                </div>
                            </div>
                        )}
                        <div className="prizes__sl-hint">
                            * Организатор не&nbsp;гарантирует, что внешний вид призов будет идентичен внешнему виду призов, которые изображены на&nbsp;сайте.
                        </div>
                    </div>
                </div>
                <div className="sc__events">
                    <div id="events" className="anchor"/>
                    <div className="events__decor-1">
                        <img src={require("../assets/img/events-decor-1.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="events__decor-2 desk-only">
                        <img src={require("../assets/img/events-decor-2.svg").default} draggable="false" loading="lazy"/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Афиша событий
                            </div>
                        </div>
                        <div className="events__wrap d-flex">
                            <div className="events__left">
                                <div className="events__left-hd">
                                    <div className="events__left-title">
                                        Ноябрь — настроение!
                                    </div>
                                    <div className="events__left-descr">
                                        Драйвовые концерты, фестивали, ночь искусств и&nbsp;стендапы&nbsp;&mdash; всё чтобы дать отпор осенней хандре!
                                    </div>
                                </div>
                                {!profile.isLoading && (!profile.loggedIn || (profile.loggedIn && profile.data.agree_pd !== 'yes')) && (
                                    <div className={'events__left-bt d-flex desk-only' + (updatingProfile ? ' form--loading' : '')}>
                                        <div onClick={()=>{
                                            if(!profile.loggedIn) {
                                                show(<Reg/>, "modal--form modal--reg");
                                            }else {
                                                updateAgree();
                                            }
                                        }} className="btn d-flex">
                                            Подписаться
                                        </div>
                                        <div className="events__left-bt-ct">
                                            <div className="events__left-bt-title">
                                                Подпишись на рассылку
                                            </div>
                                            <div className="events__left-small">
                                                и&nbsp;частвуй в&nbsp;розыгрыше ежемесячных призов
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="events__right">
                                <div className="events__sl-box">
                                    <div className="events__sl-row">
                                        <div className="events__sl">
                                            <div ref={sliderRef3} className="keen-slider">
                                                {events.map((ev, i) => (
                                                    <div key={'ev-' + i} className="keen-slider__slide">
                                                        <div className="events__slide">
                                                            <div className="events__card">
                                                                <div className="events__card-img">
                                                                    <img src={ev.img} draggable="false" loading="lazy"/>
                                                                </div>
                                                                <div className="events__card-title">
                                                                    {parse(ev.title)}
                                                                </div>
                                                                <div className="events__card-descr">
                                                                    {parse(ev.descr)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sl__nav-box">
                                    <div onClick={()=>{
                                        instanceRef3.current?.prev();
                                    }} className="sl__nav sl__nav--prev">
                                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.66683 22.0001C3.66683 32.1253 11.8749 40.3334 22.0002 40.3334C32.1254 40.3334 40.3335 32.1253 40.3335 22.0001C40.3335 11.8749 32.1254 3.66675 22.0002 3.66675C11.8749 3.66675 3.66683 11.8749 3.66683 22.0001Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M22 14.6667L14.6667 22.0001L22 29.3334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M29.3335 22L14.6668 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                    <div onClick={()=>{
                                        instanceRef3.current?.next();
                                    }} className="sl__nav sl__nav--next">
                                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.66683 22.0001C3.66683 32.1253 11.8749 40.3334 22.0002 40.3334C32.1254 40.3334 40.3335 32.1253 40.3335 22.0001C40.3335 11.8749 32.1254 3.66675 22.0002 3.66675C11.8749 3.66675 3.66683 11.8749 3.66683 22.0001Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M22.0002 29.3334L29.3335 22.0001L22.0002 14.6667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.6668 22L29.3335 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="events__subscribe mb-only">
                                <div className="events__left-bt-ct">
                                    <div className="events__left-bt-title">
                                        Подпишись на рассылку
                                    </div>
                                    <div className="events__left-small">
                                        и&nbsp;частвуй в&nbsp;розыгрыше ежемесячных призов
                                    </div>
                                </div>
                                <div onClick={()=>{
                                    if(!profile.loggedIn) {
                                        show(<Reg/>, "modal--form modal--reg");
                                    }else {
                                        updateAgree();
                                    }
                                }} className="btn d-flex">
                                    Подписаться
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__winners">
                    <div id="winners" className="anchor"/>
                    <div className="winners__decor-1">
                        <img src={require("../assets/img/win-decor-1.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="winners__decor-2">
                        <img src={require("../assets/img/win-decor-2.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                Победители
                            </div>
                        </div>
                        <div className="winners__wrap">
                            <div className="winners__filter-box">
                                <div className="winners__filter">
                                    <div className="winners__filter-scroll">
                                        {periods.map((it, i) => (
                                            <div onClick={()=>{
                                                setActivePeriod(it);
                                            }} key={'per-' + i} className={'winners__filter-it' + ((activePeriod && activePeriod.start == it.start && activePeriod.end == it.end) ? ' active' : '')}>
                                                {it.title}
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                <div className="win__search-bl">
                                    <form className={'win__search' + (searchLoading ? ' form--loading' : '')} autoComplete="off" onSubmit={handleSubmit(onSearchSubmit)}>
                                        <div className={'form__field' + ((watchEmail && watchEmail != '') ? ' has-value' : '') + (errors.email ? ' has-error' : '')}>
                                            <input
                                                type="text"
                                                id="email-search"
                                                placeholder="найти себя по e-Mail"
                                                {...register("email")}
                                            />
                                        </div>
                                        <button type="submit" className="search__submit">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.8476 16.6381L17.3428 15.1333C17.0287 14.8192 16.9627 14.3331 17.1882 13.9505C18.2174 12.2036 18.6819 10.0873 18.3106 7.84687C17.6652 3.95306 14.4529 0.874868 10.5364 0.385243C4.63206 -0.352882 -0.352882 4.63206 0.385243 10.5364C0.874868 14.4529 3.95306 17.6652 7.84687 18.3106C10.0873 18.6819 12.2036 18.2174 13.9505 17.1882C14.3332 16.9627 14.8192 17.0287 15.1333 17.3428L16.7077 18.9172C17.0126 19.2224 17.4127 19.375 17.8125 19.375C18.2502 19.375 18.6884 19.1921 19.0015 18.8261C19.5429 18.1932 19.4365 17.227 18.8476 16.6381ZM3.52087 10.3822C2.85005 6.32493 6.32493 2.85012 10.3821 3.52093C12.8402 3.92731 14.8226 5.90981 15.2291 8.36787C15.8999 12.4251 12.425 15.8999 8.36781 15.2291C5.90981 14.8227 3.9273 12.8402 3.52087 10.3822Z" fill="white"/>
                                            </svg>
                                        </button>
                                    </form>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            {winners.length > 0 && (
                                <div className={'tb tb--winners' + (loading ? ' tb--loading' : '')}>
                                    <div className="tb__head desk-only">
                                        <div className="tb__row">
                                            <div className="tb__col">
                                                <div className="tb__th">
                                                    e-mail
                                                </div>
                                            </div>
                                            <div className="tb__col">
                                                <div className="tb__th">
                                                    дата
                                                </div>
                                            </div>

                                            <div className="tb__col">
                                                <div className="tb__th">
                                                    приз
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tb__body">
                                        {winners.length > 0 && winners.map((win, i) => (
                                            <div className="tb__row">
                                                <div className="tb__col" data-title={'e-mail:'}>
                                                    <div className="tb__td">
                                                        {win.user_email}
                                                    </div>
                                                </div>
                                                <div className="tb__col" data-title={'дата:'}>
                                                    <div className="tb__td">
                                                        {win.use_at}
                                                    </div>
                                                </div>
                                                <div className="tb__col">
                                                    <div className="tb__td tb__td--prize d-flex">
                                                        <div className="tb__img">
                                                            <img src={prizes[win.type].img} draggable="false" loading="lazy"/>
                                                        </div>
                                                        <span>{win.type_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {winners.length === 0 && (
                                <div className={'tb__empty' + (loading ? ' tb--loading' : '')}>
                                    {winnersEmpty}
                                </div>
                            )}
                            {winPaging.total > 1 && (
                                renderPager()
                            )}
                        </div>
                    </div>
                </div>
                <div className="sc__faq">
                    <div id="faq" className="anchor"/>
                    <div className="faq__decor-1">
                        <img src={require("../assets/img/faq-decor-1.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="faq__decor-2">
                        <img src={require("../assets/img/faq-decor-1.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="faq__decor-3">
                        <img src={require("../assets/img/faq-decor-3.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div id="faq" className="anchor"/>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                FAQ
                            </div>
                        </div>
                        <div className="faq__wrap d-flex">
                            <div className="faq__left">
                                <div className="faq__scroll">
                                    <CustomScroll
                                        heightRelativeToParent="100%"
                                        allowOuterScroll={true}
                                    >
                                        <div className={'faq__list' + (faqFull ? ' faq__list--full' : '')}>
                                            {faq.map((fq, i) => (
                                                <div onClick={()=>{
                                                    if(faqCur === (i + 1)) {
                                                        setFaqCur(-1);
                                                    }else {
                                                        setFaqCur(i + 1);
                                                    }

                                                }} className={'faq__it fadeIn animated' + (faqCur == (i + 1) ? ' active' : '') + (i > 4 ? ' faq__it--hidden' : '')}>
                                                    <div className="faq__it-hd d-flex">
                                                        <div className="faq__it-title">
                                                            <span>{('0' + (i + 1)).slice(-2) + '. '}</span>
                                                            {parse(fq.title)}
                                                        </div>
                                                        <div className="faq__it-icon">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect y="8.5" width="20" height="3" fill="#002169"/>
                                                                <rect x="8.5" y="20" width="20" height="3" transform="rotate(-90 8.5 20)" fill="#002169"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="faq__it-answer fadeIn animated">
                                                        <div className="faq__it-txt">
                                                            {parse(fq.answer)}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CustomScroll>
                                </div>
                            </div>
                            <div className="faq__right">
                                <div className="feedback__title text-center">
                                    не нашел ответ <br className="desk-only"/>
                                    на свой вопрос?
                                </div>
                                <div className="feedback__descr text-center">
                                    Задай его нам, и мы <br className="desk-only"/>
                                    поможем!
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div onClick={()=>{
                                        show(<Feedback/>, "modal--form modal--feedback");
                                    }} className="btn btn--white d-flex">
                                        Задать вопрос
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc__stickers-2">
                    <div className="container">
                        <div className="stickers__ct">
                            <div className="stickers__imgs">
                                <div className="stickers__img">
                                    <img src={require("../assets/img/c-img-1.jpg")} draggable="false" loading="lazy"/>
                                </div>
                                <div className="stickers__img">
                                    <img src={require("../assets/img/c-img-2.jpg")} draggable="false" loading="lazy"/>
                                </div>
                                <div className="stickers__img">
                                    <img src={require("../assets/img/c-img-3.jpg")} draggable="false" loading="lazy"/>
                                </div>
                            </div>

                            <div className="stickers__title-2">
                                Выигрывай драйвовые <br className="mb-only"/>призы!
                            </div>
                        </div>
                        {!profile.loggedIn && (
                            <div onClick={()=>{
                                show(<Reg/>, "modal--form modal--reg");
                            }} className="btn d-flex">
                                участвовать
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer/>
        </>

    );
}

export default Home;
