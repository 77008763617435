import { Link, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {useModal, useProfile} from "../context";
import Feedback from "./modals/Feedback";
function Footer({screen, moveDown}) {
    const location = useLocation();
    const {profile} =  useProfile();
    const [inner, setInner] = useState(false);
    const { show, hide} = useModal();

    useEffect(() => {
        setInner(location.pathname != '/');
    }, [location.pathname]);

    return (
        <>
            <div className={'footer' + (location.pathname === '/end' ? ' footer--thin' : '')}>
                <div className="container">
                    <div className="footer__wrap d-flex">
                        <div className="footer__left">
                            <div className="footer__logo">
                                <img src={require("../assets/img/logo.svg").default} loading="lazy"/>
                            </div>
                            <div className="footer__rights desk-only">
                                © 2024 All rights reserved. Pabst
                            </div>
                        </div>
                        <div className="footer__right">
                            <div className="footer__nav">
                                <ul>
                                    <li>
                                        <a href="/rules.pdf" target="_blank">правила акции</a>
                                    </li>
                                    <li>
                                        <a href="/policy.pdf" target="_blank">политика конфиденциальности</a>
                                    </li>
                                    <li>
                                        <a onClick={()=>{
                                            show(<Feedback product={true}/>, "modal--form modal--feedback");
                                        }}>Обратная связь</a>
                                    </li>

                                </ul>
                            </div>
                            <div className="footer__legal text-center">
                                Для участия в&nbsp;акции необходимо: пройти регистрацию на&nbsp;сайте https://promo-pabst0.mosbrew.ru/, регистрация возможна с&nbsp;25&nbsp;сентября 2024 года по&nbsp;31&nbsp;января 2025&nbsp;года. Общий срок проведения акции (включая срок выдачи призов): с&nbsp;1&nbsp;октября 2024 года по&nbsp;28&nbsp;февраля 2025 года. Количество призов ограничено. Призы могут отличаться от&nbsp;изображений на&nbsp;рекламных материалах. Информация об&nbsp;организаторе акции, сроках и&nbsp;правилах ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке вручения призов размещена на&nbsp;сайте https://promo-pabst0.mosbrew.ru/.
                            </div>
                            <div className="footer__rights mb-only">
                                © 2024 All rights reserved. Pabst
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Footer;
