import { Link, useLocation  } from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {useProfile, useModal, useEnd} from "../context";
import classNames from "classnames";
import Login from "./modals/Login";
import Prize from "./modals/Prize";
import ChangePass from "./modals/ChangePass";
function Header() {
    const [nav, setNav] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [lkNav, setLkNav] = useState(false);
    const location = useLocation();
    const {end} = useEnd();
    const {profile} =  useProfile();

    const { show, hide} = useModal();
    const [inner, setInner] = useState(false);

    useEffect(() => {
        setInner(location.pathname != '/');
        window.scroll(0, 0);
        if(location.pathname != '/'){
            document.getElementById('root').removeAttribute("style");
            document.getElementById('wrapper').removeAttribute("style");
        }

        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }, [location.pathname]);

    useEffect(()=>{
        if(!profile.isLoading && Object.keys(profile.data).length > 0){
            let exist = false;
            Object.keys(profile.data.prizes).map((it, i) => {
                if(profile.data.prizes[it].push === 'no' && !exist){
                    show(<Prize prize={it} prize_id={profile.data.prizes[it].id}/>, "modal--prize");
                    exist = true;
                }
            });
        }
    }, [profile]);

    useEffect(() => {
        const handleScroll = event => {
            if(window.scrollY > 5){
                setFixed(true);
            }else{
                setFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }

    return (
        <div id="header"
             className={
                 classNames('header fadeIn animated', (inner ? ' header--inner' : ''),
                     (nav ? ' active header--open' : ''),
                     (fixed ? ' header--fixed' : ''),
                     (!profile.loading ? ' header--loaded' : '')
                 )}>
            <div className="container">
                <div className="header__left">
                    {!inner && (
                        <div onClick={()=>{
                            goToAnchor('promo');
                        }} className="logo">
                            <img src={require("../assets/img/logo.svg").default}/>
                        </div>
                    )}
                    {inner && (
                        <Link to="/" className="logo">
                            <img src={require("../assets/img/logo.svg").default}/>
                        </Link>
                    )}
                </div>
                <div className="nav__box fadeIn animated">
                    <div className="nav__bl">
                        <div className="nav__menu">
                            <ul>
                                <li>
                                    <Link to="/#steps" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('steps');
                                        }
                                    }}>как участвовать?</Link>
                                </li>
                                <li>
                                    <Link to="/#prizes" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('prizes');
                                        }
                                    }}>Призы</Link>
                                </li>
                                <li>
                                    <Link to="/#events" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('events');
                                        }
                                    }}>Афиша</Link>
                                </li>
                                <li>
                                    <Link to="/#winners" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('winners');
                                        }
                                    }}>Победители</Link>
                                </li>
                                <li>
                                    <a href="/rules.pdf" target="_blank">
                                        Правила акции
                                    </a>
                                </li>
                                <li>
                                    <Link to="/#faq" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('faq');
                                        }
                                    }}>FAQ</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="nav__bottom mb-only">
                            <Link to="/cabinet" className="btn d-flex btn--white btn--small btn--auto">
                                <span className="text-nowrap">Личный кабинет</span>
                            </Link>

                            <div className="nav__bottom-actions">
                                <div className="nav__bottom-actions-ct">
                                    <div onClick={()=>{
                                        show(<ChangePass/>, "modal--form modal--pass");
                                    }} className="btn__lk-dropdown-it d-flex align-items-center">
                                        <i className="icon__lock"/>
                                        <span>
                                   изменить пароль
                               </span>
                                    </div>
                                    <div onClick={()=>{
                                        localStorage.removeItem('auth_key');
                                        window.location.reload();
                                    }} className="btn__lk-dropdown-it d-flex align-items-center">
                                        <i className="icon__logout"/>
                                        <span>
                                   выйти из профиля
                               </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header__right d-flex desk-only">
                    {!profile.loading && (
                        <div className="header__right-it">
                            {Object.keys(profile.data).length === 0 && (
                                <div onClick={()=>{
                                    show(<Login/>, "modal--form modal--login");
                                }} className="btn d-flex btn--white btn--small btn--auto">
                                    Личный кабинет
                                </div>
                            )}

                            {Object.keys(profile.data).length > 0 && (
                                <div className={'btn__lk' + (lkNav ? ' active' : '')}>
                                    <Link to="/cabinet" className="btn d-flex btn--white btn--small btn--auto">
                                        <span className="text-nowrap">Личный кабинет</span>
                                        <div className="btn__lk-arrow">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5 9L12 15L19 9" stroke="#00143F" strokeWidth="3"/>
                                            </svg>
                                        </div>
                                    </Link>
                                    <div className="btn__lk-dropdown">
                                        <div onClick={()=>{
                                            show(<ChangePass/>, "modal--form modal--pass");
                                        }} className="btn__lk-dropdown-it d-flex align-items-center">
                                            <i className="icon__lock"/>
                                            <span>
                                               изменить пароль
                                           </span>
                                        </div>
                                        <div onClick={()=>{
                                            localStorage.removeItem('auth_key');
                                            window.location.reload();
                                        }} className="btn__lk-dropdown-it d-flex align-items-center">
                                            <i className="icon__logout"/>
                                            <span>
                                               выйти из профиля
                                           </span>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}

                </div>
                <div onClick={()=>{
                    setNav(!nav);
                    if(document.body.classList.contains('overflow')) {
                        document.body.classList.remove('overflow');
                    }else {
                        document.body.classList.add('overflow');
                    }
                }} className="nav__toggle d-flex align-items-center justify-content-center mb-only">
                    {!nav && (
                        <i className="icon__bars fadeIn animated">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 18H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M3 12H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M3 6H21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </i>
                    )}

                    {nav && (
                        <i className="icon__close fadeIn animated">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.63623 5.63611L18.3642 18.364" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5.63623 18.3639L18.3642 5.63597" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </i>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Header;
