import parse from 'html-react-parser';

function Info({title = '', subtitle = '', text = '', icon = false, close}){
    return (
        <>
            <div className="modal__ct modal__ct--success">
                {icon && (
                    <i className="icon__success" aria-hidden={true}/>
                )}
                {(title || text) && (
                    <div className="modal__ct-box">
                        {title && (
                            <div className="modal__title">
                                {parse(title)}
                            </div>
                        )}
                        {text && (
                            <div className="modal__ct-box">
                                <div className="modal__subtitle">
                                    {parse(text)}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

export default Info;
