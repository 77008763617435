import React, {createContext, useContext, useState, useMemo} from 'react';
import useEffectOnce from "../hooks/useEffectOnce";
import {useModal} from "./ModalContext";
import axiosInstance from "../helpers/axios";

const profileContextDefaultValues = {
    data: {},
    isLoading: true,
    loggedIn: false,
};

const ProfileContext = createContext({
    profileContextDefaultValues,
    setProfile: () => {}
});

export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState(profileContextDefaultValues);
    const value = useMemo(
        () => ({ profile, setProfile }),
        [profile]
    );

   const {show, hide} = useModal();

   useEffectOnce(()=>{
       if(localStorage.getItem('auth_key')){
           getProfile();
       }else{
           resetProfileData();
       }
   });

    const resetProfileData = () => {
        setProfile({
            data: {},
            isLoading: false,
            loggedIn: false,
        });
    }

    const getProfile = async(auth_key = null) => {
        try {
            const response = await axiosInstance.get('/profiles/api/profile');
            if(response.data){
                setProfile({
                    ...profile,
                    data: response.data,
                    isLoading: false,
                    loggedIn: true,
                });
            }
        } catch (error) {
            if(error.response.data){
                /*resetProfileData();
                localStorage.removeItem('auth_key');
                window.location.reload();*/
            }
        }
    }

  return (
    <ProfileContext.Provider value={value}>
        {useMemo(() => (
            children
        ), [])}
    </ProfileContext.Provider>
  )
}

export function useProfile() {
  return useContext(ProfileContext)
}