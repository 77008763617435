import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from "react-input-mask";
import * as yup from "yup";
import {useModal} from "../../context";
import Login from "./Login";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

const months = [
    {
        title: 'январь',
        id: 1
    },
    {
        title: 'февраль',
        id: 2
    },
    {
        title: 'март',
        id: 3
    },
    {
        title: 'апрель',
        id: 4
    },
    {
        title: 'май',
        id: 5
    },
    {
        title: 'июнь',
        id: 6
    },
    {
        title: 'июль',
        id: 7
    },
    {
        title: 'август',
        id: 8
    },
    {
        title: 'сентябрь',
        id: 9
    },
    {
        title: 'октябрь',
        id: 10
    },
    {
        title: 'ноябрь',
        id: 11
    },
    {
        title: 'декабрь',
        id: 12
    }
];

function Reg({email, soc = false}){
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [dateParts, setDateParts] = useState([0, 0, 0]);

    const [date, setDate] = useState('');
    const [dateTitle, setDateTitle] = useState('');
    const [isDateOpen, setIsDateOpen] = useState(false);

    const [month, setMonth] = useState('');
    const [monthTitle, setMonthTitle] = useState('');
    const [isMonthOpen, setIsMonthOpen] = useState(false);

    const [year, setYear] = useState('');
    const [yearTitle, setYearTitle] = useState('');
    const [isYearOpen, setIsYearOpen] = useState(false);

    const { show, hide} = useModal();

    const schemaObj = {
        first_name: yup.string().required('Некорректные ФИО'),
        birthday: yup.string().required('Некорректная дата'),
        phone:
            yup.string()
                .test("len", 'Некорректный телефон', (val) => {
                    const val_length_without_dashes = val.replace('+', '').replace(/-|_| /g, "").length;
                    return val_length_without_dashes === 11;
                })
                .required('Некорректный телефон'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        agree_rules: yup.boolean().oneOf([true], 'Необходимо согласие'),
        agree_pd: yup.boolean().oneOf([false, true], 'Необходимо согласие'),
    };

    const schema = yup.object(schemaObj).required();

    const { register, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchAgree1 = watch("agree_rules");
    const watchAgree2 = watch("agree_pd");

    useEffect(()=>{
        if(dateParts[0] && dateParts[1] && dateParts[2]){
            setValue('birthday', dateParts.reverse().join('-'))
        }else{
            setValue('birthday', '')
        }
    }, [dateParts]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("email", data.email);
        formData.append("first_name", data.first_name);
        formData.append("phone", data.phone);
        formData.append("birthday", data.birthday);
        formData.append("agree_rules", 'yes');
        formData.append("agree_pd", (watchAgree2 ? 'yes' : 'no'));

        try {
            const response = await axiosInstance.post('/profiles/api/register-by-email', formData);
            if(response.data){
                show(
                    <Info
                        title="Спасибо!"
                        text="Ссылка подтверждения регистрации отправлена на&nbsp;указанный e-mail"
                        icon={true}
                        close={hide}/>,
                    "modal--info"
                );
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if(email && email != 0){
            setValue('email', email);
        }

    },[]);

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__header">
                        <div className="modal__title">
                            Регистрация
                        </div>
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.first_name ? ' has-error' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            ФИО (полностью)
                                        </label>
                                        <input
                                            type="text"
                                            id="first_name"
                                            autoComplete="nope"
                                            placeholder="Введи свое ФИО"
                                            {...register("first_name")}
                                        />
                                    </div>
                                    {errors.first_name && (
                                        <p className="error__message">
                                            {errors.first_name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className="form__label">
                                        Дата рождения
                                    </div>
                                    <div className="form__select-group">
                                        <div className={'form__select' + (isDateOpen ? ' active' : '') + (date != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                            <div onClick={()=>{
                                                setIsDateOpen(!isDateOpen);
                                                setIsMonthOpen(false);
                                                setIsYearOpen(false);
                                            }} className="form__select-input d-flex">
                                                <div className={'form__select-value' + (date == '' ? ' form__select-value--placeholder' : '')}>
                                                    {dateTitle ? dateTitle : 'День'}
                                                </div>
                                                <div className="form__select-toggle">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                        <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="form__select-dropdown">
                                                <div className="form__select-dropdown-list">
                                                    {Array.from({length: 31}).map((it, i) => (
                                                        <div onClick={()=>{
                                                            setIsDateOpen(false);
                                                            setDate((i + 1));
                                                            setDateTitle(('0' + (i + 1)).slice(-2));
                                                            setDateParts([('0' + (i + 1)).slice(-2), dateParts[1], dateParts[2]])
                                                            clearErrors('birthday');
                                                        }} className={'form__select-dropdown-it' + (it == date ? ' active' : '')}>
                                                            {('0' + (i + 1)).slice(-2)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'form__select form__select--month' + (isMonthOpen ? ' active' : '') + (month != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                            <div onClick={()=>{
                                                setIsMonthOpen(!isMonthOpen);
                                                setIsDateOpen(false);
                                                setIsYearOpen(false);
                                            }} className="form__select-input d-flex">
                                                <div className={'form__select-value' + (month == '' ? ' form__select-value--placeholder' : '')}>
                                                    {monthTitle ? monthTitle : 'Месяц'}
                                                </div>
                                                <div className="form__select-toggle">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                        <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="form__select-dropdown">
                                                <div className="form__select-dropdown-list">
                                                    {months.map((it, i) => (
                                                        <div onClick={()=>{
                                                            setIsMonthOpen(false);
                                                            setMonth(it.id);
                                                            setMonthTitle(it.title);
                                                            setDateParts([dateParts[0], ('0' + it.id).slice(-2), dateParts[2]])
                                                            clearErrors('birthday');
                                                        }} className={'form__select-dropdown-it' + (it.id == month ? ' active' : '')}>
                                                            {it.title}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'form__select' + (isYearOpen ? ' active' : '') + (year != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                            <div onClick={()=>{
                                                setIsYearOpen(!isYearOpen);
                                                setIsMonthOpen(false);
                                                setIsDateOpen(false);
                                            }} className="form__select-input d-flex">
                                                <div className={'form__select-value' + (year == '' ? ' form__select-value--placeholder' : '')}>
                                                    {yearTitle ? yearTitle : 'Год'}
                                                </div>
                                                <div className="form__select-toggle">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                        <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="form__select-dropdown">
                                                <div className="form__select-dropdown-list">
                                                    {Array.from({length: 107}).map((it, i) => (
                                                        <div onClick={()=>{
                                                            setIsYearOpen(false);
                                                            setYear((i + 1900));
                                                            setYearTitle((i + 1900));
                                                            setDateParts([dateParts[0], dateParts[1], (i + 1900)])
                                                            clearErrors('birthday');
                                                        }} className={'form__select-dropdown-it' + (it == year ? ' active' : '')}>
                                                            {(i + 1900)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {errors.birthday && (
                                        <p className="error__message">
                                            {errors.birthday?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                                        <label htmlFor="email" className="form__label">
                                            e-mail
                                        </label>
                                        <input
                                            type="text"
                                            id="email"
                                            autoComplete="nope"
                                            placeholder="example@email.ru"
                                            {...register("email")}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                                        <label htmlFor="phone" className="form__label">
                                            Телефон
                                        </label>
                                        <InputMask
                                            autoComplete="nope"
                                            type="text"
                                            id="phone"
                                            mask="+7 999-999-99-99"
                                            placeholder="+7 999-999-99-99"
                                            alwaysShowMask={true}
                                            {...register("phone")}
                                        />
                                    </div>
                                    {errors.phone && (
                                        <p className="error__message">
                                            {errors.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action">
                                    <div className="checkbox__it">
                                        <div className={'checkbox__wrap' + (errors.agree_rules ? ' has-error' : '')}>
                                            <label className="align-items-start">
                                                <input
                                                    type="checkbox"
                                                    {...register("agree_rules")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Я&nbsp;согласен с&nbsp;<a href="/rules.pdf" target="_blank">правилами участия</a> и&nbsp;обработкой <a href="/user-agreement.pdf" target="_blank">персональных данных</a>
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree_rules && (
                                            <p className="error__message">
                                                {errors.agree_rules?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="checkbox__it">
                                        <div className={'checkbox__wrap' + (errors.agree_pd ? ' has-error' : '')}>
                                            <label className="align-items-start">
                                                <input
                                                    type="checkbox"
                                                    {...register("agree_pd")}
                                                />
                                                <div className="checkbox__decor"/>
                                                <div className="checkbox__title text-left">
                                                    Я согласен на получение рекламных сообщений
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree_pd && (
                                            <p className="error__message">
                                                {errors.agree_pd?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 form__it d-flex form__it-justify form__it-center form__bottom">
                                    <button disabled={isSubmitting || !watchAgree1} type="submit" className="btn btn--auto btn--small d-flex">
                                        <span>Зарегистрироваться</span>
                                    </button>
                                    <div className="modal__link text-center">
                                        Есть аккаунт? <a onClick={()=>{
                                        /*show(<Login/>, "modal--form modal--login");*/
                                        hide();
                                    }} >Войти</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Reg;
