import React, {useState, useEffect} from "react";
import {useEnd, useModal, useProfile} from "../context";

function Start() {
    const {end} = useEnd();
    const { show, hide} = useModal();

    return (
        <div className="content content--start">
            <div className="sc__start">
                <div className="sc__start-bg-mb mb-only"/>
                <div className="container">
                    <div className="start__img">
                        <picture>
                            <source media="(min-width: 1024px)" srcSet={require("../assets/img/start-img.png")}/>
                            <source media="(max-width: 1023px)" srcSet={require("../assets/img/start-img-mb.png")}/>
                            <img width="637"
                                 height="684"
                                 alt=""
                                 draggable="false" loading="lazy"
                                 src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                        </picture>
                    </div>
                    <div className="start__ct">
                        <div className="start__subtitle">
                            Уважаемый гость! Рады сообщить, что 25&nbsp;сентября 2024 года <br/>
                            начинается регистрация для участия в&nbsp;акции
                        </div>
                        <div className="start__title">
                            «Выигрывай <br/>
                            драйвовые призы»
                        </div>
                        <div className="start__descr">
                            Приглашаем вас испытать удачу и&nbsp;принять участие в&nbsp;розыгрыше призов. Регистрируйтесь на&nbsp;сайте и&nbsp;выигрывайте брендированный подарок,
                            а&nbsp;также  билеты на&nbsp;главные события города.
                        </div>
                        <div className="start__subtitle">
                            До встречи на сайте: <a href="https://promo-pabst0.mosbrew.ru">https://promo-pabst0.mosbrew.ru</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="start__legal">
                информационная страница промоакции «Выигрывай драйвовые призы»
            </div>
        </div>
    );
}

export default Start;
