import {Routes, Route, useLocation} from 'react-router-dom'
import React, {useEffect} from 'react';
import {ProfileProvider, ModalProvider, EndProvider} from "./context";
import Home from './pages/Home'
import Cabinet from "./pages/Cabinet";
import Start from "./pages/Start";
import LoginPage from "./pages/LoginPage";
import {Helmet} from "react-helmet";

function App() {
    const location = useLocation();

    return (
        <>
            <Helmet>
              <title></title>
              <meta property="og:title" content=""/>
            </Helmet>
            <EndProvider>
              <ProfileProvider>
                  <ModalProvider>
                      <div id="wrapper" className="wrapper">
                          <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/cabinet" element={<Cabinet />} />
                              <Route path="/start" element={<Start />} />
                              <Route path="/login" element={<LoginPage />} />
                          </Routes>
                      </div>
                  </ModalProvider>
              </ProfileProvider>
            </EndProvider>
        </>
    );
}

export default App;